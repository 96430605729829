export default {
	"/": {
		"type": "select",
		"label": "Langue",
		"label-alt": "Language",
		"options": [
			{
				"name": "Global English",
				"name-alt": "Global English",
				"path": "/english"
			},
			{
				"name": "Français",
				"name-alt": "French",
				"path": "/french"
			}
		]
	},
	"/english": {
		"type": "select",
		"label": "Nature du contact",
		"label-alt": "Nature of Contact",
		"options": [
			{
				"name": "Support for CS, TS, Ret issues",
				"name-alt": "Support for CS, TS, Ret issues",
				"path": "/english/support"
			},
			{
				"name": "Sales query (Pricing, plans, etc)",
				"name-alt": "Sales query (Pricing, plans, etc)",
				"path": "/english/sales"
			},
			{
				"name": "Partner Program",
				"name-alt": "Partner Program",
				"path": "/english/partner-program"
			}
		]
	},
	"/french": {
		"type": "select",
		"label": "Nature du contact",
		"label-alt": "Nature of Contact",
		"options": [
			{
				"name": "Prise en charge des problèmes CS, TS et Ret",
				"name-alt": "Support for CS, TS, Ret issues",
				"path": "/french/support"
			},
			{
				"name": "Demande de vente (prix, plans, etc.)",
				"name-alt": "Sales query (Pricing, plans, etc)",
				"path": "/french/sales"
			},
			{
				"name": "Le revendeur a contacté (Impossible de passer des commandes, suppression de la licence pour le client, problème de retour de commande, problème de console du revendeur, problèmes de prix)",
				"name-alt": "Reseller has contacted (Unable to place Orders, remove license for customer, return order issue, reseller console issue, pricing issues)",
				"path": "/french/reseller"
			}
		]
	},
	"/french/support": {
		"type": "select",
		"label": "Quel type de licence est demandé par le client ?",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "Enterprise (EVIP et ETLA)",
				"name-alt": "Enterprise (EVIP and ETLA)",
				"path": "/french/support/enterprise"
			},
			{
				"name": "Abonnement individuel, Stock pour individu",
				"name-alt": "Individual Subscription, Stock for Individual",
				"path": "/french/support/individual"
			},
			{
				"name": "Licence individuelle perpétuelle",
				"name-alt": "Perpetual Individual License",
				"path": "/french/support/perpetual"
			},
			{
				"name": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"name-alt": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"path": "/french/support/ppbu"
			},
			{
				"name": "SAAS - Export PDF, PDF Pack",
				"name-alt": "SAAS - Export PDF, PDF Pack",
				"path": "/french/support/saas"
			},
			{
				"name": "Abonnement d'équipe, Stock pour l'équipe, licence en volume",
				"name-alt": "Team Subscription, Stock for Team, Volume license",
				"path": "/french/support/team"
			}
		]
	},
	"/french/support/enterprise": {
		"type": "results",
		"message": "Conseillez au client de parler à son administrateur de système ou à son administrateur de compte Adobe pour nous contacter via l'admin console",
		"message-alt": "Advise customer to speak to their respective IT admin or Adobe account administrator to contact us via the admin console"
	},
	"/french/support/individual": {
		"type": "select",
		"label": "Type de numéro - Abonnements individuels",
		"label-alt": "Issue Type - Individual Subscriptions",
		"options": [
			{
				"name": "Problèmes liés au compte et à la facturation",
				"name-alt": "Account and Billing Issues",
				"path": "/french/support/individual/account"
			},
			{
				"name": "Annulation de l'abonnement ou prélèvements inconnus",
				"name-alt": "Cancel Subscription or unknown charge",
				"path": "/french/support/individual/cancel"
			},
			{
				"name": "Aide technique ou relative au produit",
				"name-alt": "Technical or product help",
				"path": "/french/support/individual/technical"
			},
			{
				"name": "Code de remboursement révoqué \ \ sur liste noire",
				"name-alt": "Revoked \\ Blacklisted Redemption Code",
				"path": "/french/support/individual/revoked"
			}
		]
	},
	"/french/support/individual/account": {
		"type": "results",
		"queue": "CCI-Fr-CS"
	},
	"/french/support/individual/cancel": {
		"type": "results",
		"queue": "RET-FrPriority-CCI"
	},
	"/french/support/individual/technical": {
		"type": "select",
		"label": "Produits - Abonnements individuels",
		"label-alt": "Products - Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/french/support/individual/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/french/support/individual/technical/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/french/support/individual/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/french/support/individual/technical/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/french/support/individual/technical/muse"
			},
			{
				"name": "Autres produits creative cloud",
				"name-alt": "Other creative cloud products",
				"path": "/french/support/individual/technical/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/french/support/individual/technical/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/french/support/individual/technical/premier"
			},
			{
				"name": "Stock individuel",
				"name-alt": "Stock for Individual",
				"path": "/french/support/individual/technical/stock"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/french/support/individual/technical/substance"
			}
		]
	},
	"/french/support/individual/technical/acrobat": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/dreamweaver": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/firefly": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/illustrator": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/muse": {
		"type": "results",
		"message": "Indiquez au client que le produit est hors service et que le problème est pris en charge sur le forum communautaire. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>"
	},
	"/french/support/individual/technical/other": {
		"type": "results",
		"message": "Consultez SME ou recherchez dans la base de connaissances",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/french/support/individual/technical/photoshop": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/premier": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/stock": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/technical/substance": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/individual/revoked": {
		"type": "results",
		"queue": "AG-Fr-CC"
	},
	"/french/support/perpetual": {
		"type": "select",
		"label": "Type de problème : licence AVL perpétuelle",
		"label-alt": "Issue Type - Perpetual AVL License",
		"options": [
			{
				"name": "Gestion du compte",
				"name-alt": "Account management",
				"path": "/french/support/perpetual/account"
			},
			{
				"name": "Le numéro de série n'est pas une véritable erreur",
				"name-alt": "Serial number not genuine error",
				"path": "/french/support/perpetual/serial-number-not-genuine"
			},
			{
				"name": "Question technique - Comment faire",
				"name-alt": "Technical Question - How To",
				"path": "/french/support/perpetual/technical"
			}
		]
	},
	"/french/support/perpetual/account": {
		"type": "select",
		"label": "Produits individuels perpétuels",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/french/support/perpetual/account/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/french/support/perpetual/account/lightroom"
			},
			{
				"name": "Tout autre produit perpétuel non répertorié ici",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/french/support/perpetual/account/other"
			},
			{
				"name": "Photoshop or Premier Elements 20, 21, 22",
				"name-alt": "Photoshop or Premier Elements 20, 21, 22",
				"path": "/french/support/perpetual/account/photoshop"
			}
		]
	},
	"/french/support/perpetual/account/acrobat": {
		"type": "results",
		"queue": "PPTL-Fr-Uni"
	},
	"/french/support/perpetual/account/lightroom": {
		"type": "results",
		"message": "Indiquez au client que le produit est hors service et que le problème est pris en charge sur le forum communautaire. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>"
	},
	"/french/support/perpetual/account/other": {
		"type": "results",
		"message": "Indiquez au client que le produit est hors service et que le problème est pris en charge sur le forum communautaire. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>"
	},
	"/french/support/perpetual/account/photoshop": {
		"type": "results",
		"queue": "PPTL-Fr-Uni"
	},
	"/french/support/perpetual/serial-number-not-genuine": {
		"type": "results",
		"queue": "AG-Fr-CC"
	},
	"/french/support/perpetual/technical": {
		"type": "results",
		"queue": "PPTL-Fr-Uni"
	},
	"/french/support/ppbu": {
		"type": "results",
		"queue": "CCI-Fr-TS"
	},
	"/french/support/saas": {
		"type": "select",
		"label": "Type de problème - SAAS",
		"label-alt": "Issue Type - SAAS",
		"options": [
			{
				"name": "Problèmes liés au compte et à la facturation",
				"name-alt": "Account and Billing Issues",
				"path": "/french/support/saas/account"
			},
			{
				"name": "Annulation de l'abonnement ou prélèvements inconnus",
				"name-alt": "Cancel Subscription or unknown charge",
				"path": "/french/support/saas/cancel"
			},
			{
				"name": "Aide technique ou relative au produit",
				"name-alt": "Technical or product help",
				"path": "/french/support/saas/technical"
			}
		]
	},
	"/french/support/saas/account": {
		"type": "results",
		"queue": "CCI-Fr-CS"
	},
	"/french/support/saas/cancel": {
		"type": "results",
		"queue": "RET-FrPriority-CCI"
	},
	"/french/support/saas/technical": {
		"type": "results",
		"message": "Indiquez au client que le produit est hors service et que le problème est pris en charge sur le forum communautaire. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>"
	},
	"/french/support/team": {
		"type": "select",
		"label": "Type de numéro - Abonnements CCTeam",
		"label-alt": "Issue Type - CCTeam Subscriptions",
		"options": [
			{
				"name": "Gestion du compte",
				"name-alt": "Account management",
				"path": "/french/support/team/account"
			},
			{
				"name": "Activation, problèmes liés au premier lancement, problèmes de synchronisation des fichiers",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/french/support/team/activation"
			},
			{
				"name": "Changer le nom de l'administrateur ou de l'équipe",
				"name-alt": "Changing the Admin or team name",
				"path": "/french/support/team/admin"
			},
			{
				"name": "Adobe ID",
				"name-alt": "Adobe ID",
				"path": "/french/support/team/adobe-id"
			},
			{
				"name": "Problème de facturation ou de paiement",
				"name-alt": "Billing or payment issue",
				"path": "/french/support/team/billing"
			},
			{
				"name": "Annuler l'abonnement",
				"name-alt": "Cancel Subscription",
				"path": "/french/support/team/cancel"
			},
			{
				"name": "CCT VIP",
				"name-alt": "CCT VIP",
				"path": "/french/support/team/cct-vip"
			},
			{
				"name": "Question sur les frais, le changement de plan, le changement de mode de paiement, la mise à jour de la carte de crédit",
				"name-alt": "Question on charges, change of plan, payment mode change, credit card update",
				"path": "/french/support/team/charges"
			},
			{
				"name": "Déploiement, téléchargement, installation",
				"name-alt": "Deployment, Download, Install",
				"path": "/french/support/team/deployment"
			},
			{
				"name": "Attribuer/annuler l'attribution de licences",
				"name-alt": "Assign/Unassign licenses",
				"path": "/french/support/team/licenses"
			},
			{
				"name": "Demande de changement de revendeur",
				"name-alt": "Reseller change request",
				"path": "/french/support/team/reseller"
			},
			{
				"name": "Licence perpétuelle - Retour ou remboursement",
				"name-alt": "Perpetual license - Return or Refund",
				"path": "/french/support/team/return"
			},
			{
				"name": "Numéro de série perdu ou ne fonctionne pas",
				"name-alt": "Serial number lost or not working",
				"path": "/french/support/team/serial-number-lost"
			},
			{
				"name": "Question technique - Comment faire",
				"name-alt": "Technical Question - How To",
				"path": "/french/support/team/technical"
			}
		]
	},
	"/french/support/team/account": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/activation": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/admin": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/adobe-id": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/billing": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/cancel": {
		"type": "results",
		"queue": "RET-Fr-CCT"
	},
	"/french/support/team/cct-vip": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/charges": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/deployment": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/licenses": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/reseller": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/return": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/serial-number-lost": {
		"type": "results",
		"queue": "CCT-Fr-CS"
	},
	"/french/support/team/technical": {
		"type": "select",
		"label": "Produits - Abonnements CCTeam",
		"label-alt": "Products - CCTeam Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/french/support/team/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/french/support/team/technical/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/french/support/team/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/french/support/team/technical/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/french/support/team/technical/muse"
			},
			{
				"name": "Autres produits Creative Cloud",
				"name-alt": "Other creative cloud products",
				"path": "/french/support/team/technical/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/french/support/team/technical/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/french/support/team/technical/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/french/support/team/technical/sign"
			},
			{
				"name": "Stock for Team",
				"name-alt": "Stock for Team",
				"path": "/french/support/team/technical/stock"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/french/support/team/technical/substance"
			}
		]
	},
	"/french/support/team/technical/acrobat": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/dreamweaver": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/firefly": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/illustrator": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/muse": {
		"type": "results",
		"message": "Informez le client que le produit est EOL (fin de vie) et que le problème est supporté sur le forum communautaire. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/?profile.language=fr\">Forum Link</a>"
	},
	"/french/support/team/technical/other": {
		"type": "results",
		"message": "Consultez un SME ou recherchez dans la KB",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/french/support/team/technical/photoshop": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/premier": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/sign": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/stock": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/support/team/technical/substance": {
		"type": "results",
		"queue": "CCT-Fr-TS"
	},
	"/french/sales": {
		"type": "select",
		"label": "Quel type de licence est demandé par le client ?",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "Individuel",
				"name-alt": "Individual",
				"path": "/french/sales/individual"
			},
			{
				"name": "Abonnement Team",
				"name-alt": "Team Subscription",
				"path": "/french/sales/team"
			},
			{
				"name": "Sign (Ind, Team, Ent)",
				"name-alt": "Sign (Ind, Team, Ent)",
				"path": "/french/sales/sign"
			},
			{
				"name": "Stock (Ind, Team, Ent)",
				"name-alt": "Stock (Ind, Team, Ent)",
				"path": "/french/sales/stock"
			}
		]
	},
	"/french/sales/individual": {
		"type": "results",
		"queue": "SLS-CCI-Fr"
	},
	"/french/sales/team": {
		"type": "results",
		"queue": "SLS-CCT-Fr"
	},
	"/french/sales/sign": {
		"type": "filter",
		"label": "Chaîne",
		"label-alt": "Channel",
		"options": [
			{
				"name": "Chat",
				"name-alt": "Chat",
				"path": "/french/sales/sign/chat"
			},
			{
				"name": "Voix",
				"name-alt": "Voice",
				"path": "/french/sales/sign/voice"
			}
		]
	},
	"/french/sales/sign/chat": {
		"type": "results",
		"queue": "SLS-DC-Fr"
	},
	"/french/sales/sign/voice": {
		"type": "results",
		"queue": "SLS-Sign-Fr"
	},
	"/french/sales/stock": {
		"type": "results",
		"queue": "SLS-STOCK-Fr"
	},
	"/french/reseller": {
		"type": "results",
		"queue": "DNL-APC-Fr-PHD"
	},
	"/english/support": {
		"type": "select",
		"label": "Customer calling for which license?",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "Enterprise (EVIP and ETLA)",
				"name-alt": "Enterprise (EVIP and ETLA)",
				"path": "/english/support/enterprise"
			},
			{
				"name": "Individual Subscription",
				"name-alt": "Individual Subscription",
				"path": "/english/support/individual"
			},
			{
				"name": "Perpetual Individual License",
				"name-alt": "Perpetual Individual License",
				"path": "/english/support/perpetual"
			},
			{
				"name": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"name-alt": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"path": "/english/support/ppbu"
			},
			{
				"name": "SAAS - Export PDF, PDF Pack",
				"name-alt": "SAAS - Export PDF, PDF Pack",
				"path": "/english/support/saas"
			},
			{
				"name": "Stock for Individual",
				"name-alt": "Stock for Individual",
				"path": "/english/support/stock-individual"
			},
			{
				"name": "Stock for Team",
				"name-alt": "Stock for Team",
				"path": "/english/support/stock-team"
			},
			{
				"name": "Team Subscription",
				"name-alt": "Team Subscription",
				"path": "/english/support/team"
			},
			{
				"name": "Volume License - AVL Perpetual",
				"name-alt": "Volume License - AVL Perpetual",
				"path": "/english/support/volume"
			}
		]
	},
	"/english/support/enterprise": {
		"type": "select",
		"label": "Profile of ENT Customer",
		"label-alt": "Profile of ENT Customer",
		"options": [
			{
				"name": "Admin",
				"name-alt": "Admin",
				"path": "/english/support/enterprise/admin"
			},
			{
				"name": "ASP Service Desk",
				"name-alt": "ASP Service Desk",
				"path": "/english/support/enterprise/asp-service-desk"
			},
			{
				"name": "End User",
				"name-alt": "End User",
				"path": "/english/support/enterprise/end-user"
			}
		]
	},
	"/english/support/enterprise/admin": {
		"type": "select",
		"label": "Issue Type - Enterprise License",
		"label-alt": "Issue Type - Enterprise License",
		"options": [
			{
				"name": "Acrobat Activation, Acrobat Deployment, Acrobat Download/Install",
				"name-alt": "Acrobat Activation, Acrobat Deployment, Acrobat Download/Install",
				"path": "/english/support/enterprise/admin/acrobat-activation"
			},
			{
				"name": "CC Activation, CC Download/Install, Library Sync Issue",
				"name-alt": "CC Activation, CC Download/Install, Library Sync Issue",
				"path": "/english/support/enterprise/admin/cc-activation"
			},
			{
				"name": "Console Issue, SSO Setup, Expert Sessions, Deployment, etc",
				"name-alt": "Console Issue, SSO Setup, Expert Sessions, Deployment, etc",
				"path": "/english/support/enterprise/admin/console"
			},
			{
				"name": "Product Questions",
				"name-alt": "Product Questions",
				"path": "/english/support/enterprise/admin/product"
			}
		]
	},
	"/english/support/enterprise/admin/acrobat-activation": {
		"type": "results",
		"queue": "ENT-DC-SD-En"
	},
	"/english/support/enterprise/admin/cc-activation": {
		"type": "results",
		"queue": "ENT-Admin-SD-En"
	},
	"/english/support/enterprise/admin/console": {
		"type": "results",
		"queue": "ENT-Admin-SD-En"
	},
	"/english/support/enterprise/admin/product": {
		"type": "filter",
		"label": "Channel",
		"label-alt": "Channel",
		"options": [
			{
				"name": "Voice",
				"name-alt": "Voice",
				"path": "/english/support/enterprise/admin/product/voice"
			},
			{
				"name": "Chat",
				"name-alt": "Chat",
				"path": "/english/support/enterprise/admin/product/chat"
			}
		]
	},
	"/english/support/enterprise/admin/product/voice": {
		"type": "select",
		"label": "Products - Enterprise",
		"label-alt": "Products - Enterprise",
		"options": [
			{
				"name": "Acrobat",
				"name-alt": "Acrobat",
				"path": "/english/support/enterprise/admin/product/voice/acrobat"
			},
			{
				"name": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"name-alt": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"path": "/english/support/enterprise/admin/product/voice/captivate"
			},
			{
				"name": "Connect",
				"name-alt": "Connect",
				"path": "/english/support/enterprise/admin/product/voice/connect"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/english/support/enterprise/admin/product/voice/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/enterprise/admin/product/voice/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"path": "/english/support/enterprise/admin/product/voice/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/english/support/enterprise/admin/product/voice/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/english/support/enterprise/admin/product/voice/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"name-alt": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"path": "/english/support/enterprise/admin/product/voice/premiere"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/english/support/enterprise/admin/product/voice/sign"
			},
			{
				"name": "Stock",
				"name-alt": "Stock",
				"path": "/english/support/enterprise/admin/product/voice/stock"
			}
		]
	},
	"/english/support/enterprise/admin/product/voice/acrobat": {
		"type": "results",
		"queue": "ENT-En-SD-Acro"
	},
	"/english/support/enterprise/admin/product/voice/captivate": {
		"type": "results",
		"queue": "TS-En-Ppbu"
	},
	"/english/support/enterprise/admin/product/voice/connect": {
		"type": "results",
		"message": "Provide this number to the customer and inform them to contact 1-800-422-3623 and select Option 2",
		"message-alt": "Provide this number to the customer and inform them to contact 1-800-422-3623 and select Option 2"
	},
	"/english/support/enterprise/admin/product/voice/dreamweaver": {
		"type": "results",
		"queue": "ENT-En-SD-Web"
	},
	"/english/support/enterprise/admin/product/voice/firefly": {
		"type": "results",
		"queue": "ENT-En-SD-Firefly"
	},
	"/english/support/enterprise/admin/product/voice/illustrator": {
		"type": "results",
		"queue": "ENT-En-SD-Print"
	},
	"/english/support/enterprise/admin/product/voice/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/enterprise/admin/product/voice/photoshop": {
		"type": "results",
		"queue": "ENT-En-SD-Imaging"
	},
	"/english/support/enterprise/admin/product/voice/premiere": {
		"type": "results",
		"queue": "ENT-En-SD-Video"
	},
	"/english/support/enterprise/admin/product/voice/sign": {
		"type": "results",
		"queue": "ENT-Sign-SD-En"
	},
	"/english/support/enterprise/admin/product/voice/stock": {
		"type": "results",
		"queue": "ENT-En-SD-Stock"
	},
	"/english/support/enterprise/admin/product/chat": {
		"type": "select",
		"label": "Products - Enterprise",
		"label-alt": "Products - Enterprise",
		"options": [
			{
				"name": "Acrobat",
				"name-alt": "Acrobat",
				"path": "/english/support/enterprise/admin/product/chat/acrobat"
			},
			{
				"name": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"name-alt": "Captivate, Captivate Draft, FrameMaker, FrameMaker Publishing Server, Presenter, Presenter Video Express, RoboHelp, RoboHelp Server, Technical Communication Suite",
				"path": "/english/support/enterprise/admin/product/chat/captivate"
			},
			{
				"name": "Connect",
				"name-alt": "Connect",
				"path": "/english/support/enterprise/admin/product/chat/connect"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/english/support/enterprise/admin/product/chat/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/enterprise/admin/product/chat/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp, Illustrator Draw",
				"path": "/english/support/enterprise/admin/product/chat/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/english/support/enterprise/admin/product/chat/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/english/support/enterprise/admin/product/chat/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"name-alt": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder, Speed Grade, Team Project",
				"path": "/english/support/enterprise/admin/product/chat/premiere"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/english/support/enterprise/admin/product/chat/sign"
			},
			{
				"name": "Stock",
				"name-alt": "Stock",
				"path": "/english/support/enterprise/admin/product/chat/stock"
			}
		]
	},
	"/english/support/enterprise/admin/product/chat/acrobat": {
		"type": "results",
		"queue": "ENT-DC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/captivate": {
		"type": "results",
		"queue": "ENT-PPBU-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/connect": {
		"type": "results",
		"queue": "ENT-DX-Connect-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/dreamweaver": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/firefly": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/illustrator": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/enterprise/admin/product/chat/photoshop": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/premiere": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/sign": {
		"type": "results",
		"queue": "ENT-Sign-SD-En"
	},
	"/english/support/enterprise/admin/product/chat/stock": {
		"type": "results",
		"queue": "ENT-CC-SD-En"
	},
	"/english/support/enterprise/asp-service-desk": {
		"type": "results",
		"queue": "ENT-ASP-En-SD"
	},
	"/english/support/enterprise/end-user": {
		"type": "results",
		"message": "Advise customer to speak to their respective IT admin or Adobe account administrator to contact us via the admin console",
		"message-alt": "Advise customer to speak to their respective IT admin or Adobe account administrator to contact us via the admin console"
	},
	"/english/support/individual": {
		"type": "select",
		"label": "Issue Type - Individual Subscriptions",
		"label-alt": "Issue Type - Individual Subscriptions",
		"options": [
			{
				"name": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related questions",
				"name-alt": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related questions",
				"path": "/english/support/individual/account"
			},
			{
				"name": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"name-alt": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"path": "/english/support/individual/billing-authorization"
			},
			{
				"name": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"name-alt": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"path": "/english/support/individual/billing-invoice"
			},
			{
				"name": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"name-alt": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"path": "/english/support/individual/billing-payment"
			},
			{
				"name": "Cancellation for known charge including TWP Charged - Save opportunity",
				"name-alt": "Cancellation for known charge including TWP Charged - Save opportunity",
				"path": "/english/support/individual/cancel"
			},
			{
				"name": "Unknown charge - charge identified- disaffirm and wants to cancel",
				"name-alt": "Unknown charge - charge identified- disaffirm and wants to cancel",
				"path": "/english/support/individual/charge-disaffirm"
			},
			{
				"name": "Unknown charge - charge identified- not disaffirm and still wants to cancel",
				"name-alt": "Unknown charge - charge identified- not disaffirm and still wants to cancel",
				"path": "/english/support/individual/charge-not-disaffirm"
			},
			{
				"name": "Unknown charge - charge not identified",
				"name-alt": "Unknown charge - charge not identified",
				"path": "/english/support/individual/charge-not-identified"
			},
			{
				"name": "TS-Download, Install questions,Activation, Subscription converted to trial, CCDA any issue including sync",
				"name-alt": "TS-Download, Install questions,Activation, Subscription converted to trial, CCDA any issue including sync",
				"path": "/english/support/individual/download"
			},
			{
				"name": "TS-First Launch Issues, File Sync Issues-product related, How to and technical help",
				"name-alt": "TS-First Launch Issues, File Sync Issues-product related, How to and technical help",
				"path": "/english/support/individual/launch"
			},
			{
				"name": "Revoked \\ Blacklisted Redemption Code",
				"name-alt": "Revoked \\ Blacklisted Redemption Code",
				"path": "/english/support/individual/revoked"
			}
		]
	},
	"/english/support/individual/account": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/individual/billing-authorization": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/individual/billing-invoice": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/individual/billing-payment": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/individual/cancel": {
		"type": "results",
		"queue": "RET-En-CCI"
	},
	"/english/support/individual/charge-disaffirm": {
		"type": "results",
		"queue": "RET-En-CCI"
	},
	"/english/support/individual/charge-not-disaffirm": {
		"type": "results",
		"queue": "RET-En-CCI"
	},
	"/english/support/individual/charge-not-identified": {
		"type": "results",
		"queue": "RET-En-CCI"
	},
	"/english/support/individual/download": {
		"type": "select",
		"label": "Products- Individual Subscriptions",
		"label-alt": "Products- Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/english/support/individual/download/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/english/support/individual/download/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/individual/download/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/english/support/individual/download/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/english/support/individual/download/muse"
			},
			{
				"name": "Other creative cloud products",
				"name-alt": "Other creative cloud products",
				"path": "/english/support/individual/download/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/english/support/individual/download/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/english/support/individual/download/premiere"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/english/support/individual/download/substance"
			}
		]
	},
	"/english/support/individual/download/acrobat": {
		"type": "results",
		"queue": "TS-En-Acro"
	},
	"/english/support/individual/download/dreamweaver": {
		"type": "results",
		"queue": "TS-En-Web"
	},
	"/english/support/individual/download/firefly": {
		"type": "results",
		"queue": "TS-En-Firefly"
	},
	"/english/support/individual/download/illustrator": {
		"type": "results",
		"queue": "TS-En-Design"
	},
	"/english/support/individual/download/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/individual/download/other": {
		"type": "results",
		"message": "Consult SME or search in knowledge base",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/english/support/individual/download/photoshop": {
		"type": "results",
		"queue": "TS-En-Photo"
	},
	"/english/support/individual/download/premiere": {
		"type": "results",
		"queue": "TS-En-Video"
	},
	"/english/support/individual/download/substance": {
		"type": "results",
		"queue": "TS-En-3D"
	},
	"/english/support/individual/launch": {
		"type": "select",
		"label": "Products- Individual Subscriptions",
		"label-alt": "Products- Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/english/support/individual/launch/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/english/support/individual/launch/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/individual/launch/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/english/support/individual/launch/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/english/support/individual/launch/muse"
			},
			{
				"name": "Other creative cloud products",
				"name-alt": "Other creative cloud products",
				"path": "/english/support/individual/launch/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/english/support/individual/launch/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/english/support/individual/launch/premiere"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/english/support/individual/launch/substance"
			}
		]
	},
	"/english/support/individual/launch/acrobat": {
		"type": "results",
		"queue": "TS-En-Acro"
	},
	"/english/support/individual/launch/dreamweaver": {
		"type": "results",
		"queue": "TS-En-Web"
	},
	"/english/support/individual/launch/firefly": {
		"type": "results",
		"queue": "TS-En-Firefly"
	},
	"/english/support/individual/launch/illustrator": {
		"type": "results",
		"queue": "TS-En-Design"
	},
	"/english/support/individual/launch/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/individual/launch/other": {
		"type": "results",
		"message": "Consult SME or search in knowledge base",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/english/support/individual/launch/photoshop": {
		"type": "results",
		"queue": "TS-En-Photo"
	},
	"/english/support/individual/launch/premiere": {
		"type": "results",
		"queue": "TS-En-Video"
	},
	"/english/support/individual/launch/substance": {
		"type": "results",
		"queue": "TS-En-3D"
	},
	"/english/support/individual/revoked": {
		"type": "results",
		"queue": "AG-En-CC"
	},
	"/english/support/perpetual": {
		"type": "select",
		"label": "Issue Type-Perpetual AVL License",
		"label-alt": "Issue Type-Perpetual AVL License",
		"options": [
			{
				"name": "Account management",
				"name-alt": "Account management",
				"path": "/english/support/perpetual/account"
			},
			{
				"name": "Activation or Billing",
				"name-alt": "Activation or Billing",
				"path": "/english/support/perpetual/activation"
			},
			{
				"name": "Download, Install",
				"name-alt": "Download, Install",
				"path": "/english/support/perpetual/download"
			},
			{
				"name": "Return and Refund",
				"name-alt": "Return and Refund",
				"path": "/english/support/perpetual/return"
			},
			{
				"name": "Serial number Lost or missing or not working",
				"name-alt": "Serial number Lost or missing or not working",
				"path": "/english/support/perpetual/serial-number-lost"
			},
			{
				"name": "Serial number not Genuine error",
				"name-alt": "Serial number not Genuine error",
				"path": "/english/support/perpetual/serial-number-not-genuine"
			},
			{
				"name": "Technical- How To",
				"name-alt": "Technical- How To",
				"path": "/english/support/perpetual/technical"
			}
		]
	},
	"/english/support/perpetual/account": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/account/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/account/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/account/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/account/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/account/premiere"
			}
		]
	},
	"/english/support/perpetual/account/acrobat": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/account/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/account/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/account/photoshop": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/account/premiere": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/activation": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/activation/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/activation/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/activation/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/activation/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/activation/premiere"
			}
		]
	},
	"/english/support/perpetual/activation/acrobat": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/activation/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/activation/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/activation/photoshop": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/activation/premiere": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/download": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/download/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/download/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/download/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/download/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/download/premiere"
			}
		]
	},
	"/english/support/perpetual/download/acrobat": {
		"type": "results",
		"queue": "PPTL-En-Inst"
	},
	"/english/support/perpetual/download/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/download/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/download/photoshop": {
		"type": "results",
		"queue": "PPTL-En-Inst"
	},
	"/english/support/perpetual/download/premiere": {
		"type": "results",
		"queue": "PPTL-En-Inst"
	},
	"/english/support/perpetual/return": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/return/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/return/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/return/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/return/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/return/premiere"
			}
		]
	},
	"/english/support/perpetual/return/acrobat": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/return/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/return/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/return/photoshop": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/return/premiere": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/serial-number-lost": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/serial-number-lost/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/serial-number-lost/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/serial-number-lost/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/serial-number-lost/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/serial-number-lost/premiere"
			}
		]
	},
	"/english/support/perpetual/serial-number-lost/acrobat": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/serial-number-lost/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/serial-number-lost/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/serial-number-lost/photoshop": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/serial-number-lost/premiere": {
		"type": "results",
		"queue": "PPTL-En-CS"
	},
	"/english/support/perpetual/serial-number-not-genuine": {
		"type": "results",
		"queue": "AG-En-CC"
	},
	"/english/support/perpetual/technical": {
		"type": "select",
		"label": "Perpetual Individual Products",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/english/support/perpetual/technical/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/english/support/perpetual/technical/lightroom"
			},
			{
				"name": "Any other perpetual product not listed here",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/english/support/perpetual/technical/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/english/support/perpetual/technical/photoshop"
			},
			{
				"name": "Premiere Elements 20, 21, 22",
				"name-alt": "Premiere Elements 20, 21, 22",
				"path": "/english/support/perpetual/technical/premiere"
			}
		]
	},
	"/english/support/perpetual/technical/acrobat": {
		"type": "results",
		"queue": "TS-En-Acro"
	},
	"/english/support/perpetual/technical/lightroom": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/technical/other": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/perpetual/technical/photoshop": {
		"type": "results",
		"queue": "TS-En-Pepe"
	},
	"/english/support/perpetual/technical/premiere": {
		"type": "results",
		"queue": "TS-En-Pepe"
	},
	"/english/support/ppbu": {
		"type": "select",
		"label": "Products - PPBU",
		"label-alt": "Products - PPBU",
		"options": [
			{
				"name": "Captivate (NOT PRIME)",
				"name-alt": "Captivate (NOT PRIME)",
				"path": "/english/support/ppbu/captivate"
			},
			{
				"name": "Captivate Prime",
				"name-alt": "Captivate Prime",
				"path": "/english/support/ppbu/captivate-prime"
			},
			{
				"name": "Cold Fusion",
				"name-alt": "Cold Fusion",
				"path": "/english/support/ppbu/cold-fusion"
			},
			{
				"name": "FrameMaker, FrameMaker Publishing Server",
				"name-alt": "FrameMaker, FrameMaker Publishing Server",
				"path": "/english/support/ppbu/framemaker"
			},
			{
				"name": "Presenter, Presenter Video Express",
				"name-alt": "Presenter, Presenter Video Express",
				"path": "/english/support/ppbu/presenter"
			},
			{
				"name": "RoboHelp, RoboHelp Server",
				"name-alt": "RoboHelp, RoboHelp Server",
				"path": "/english/support/ppbu/robohelp"
			}
		]
	},
	"/english/support/ppbu/captivate": {
		"type": "results",
		"queue": "TS-En-Ppbu"
	},
	"/english/support/ppbu/captivate-prime": {
		"type": "results",
		"queue": "DNL-CP-En-Prime"
	},
	"/english/support/ppbu/cold-fusion": {
		"type": "results",
		"queue": "DNL-CF-En-ColdFusion"
	},
	"/english/support/ppbu/framemaker": {
		"type": "results",
		"queue": "TS-En-Ppbu"
	},
	"/english/support/ppbu/presenter": {
		"type": "results",
		"queue": "TS-En-Ppbu"
	},
	"/english/support/ppbu/robohelp": {
		"type": "results",
		"queue": "TS-En-Ppbu"
	},
	"/english/support/saas": {
		"type": "select",
		"label": "Issue Type - SASS",
		"label-alt": "Issue Type - SASS",
		"options": [
			{
				"name": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"name-alt": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"path": "/english/support/saas/account"
			},
			{
				"name": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"name-alt": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"path": "/english/support/saas/billing-authorization"
			},
			{
				"name": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received,",
				"name-alt": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received,",
				"path": "/english/support/saas/billing-invoice"
			},
			{
				"name": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"name-alt": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"path": "/english/support/saas/billing-payment"
			},
			{
				"name": "Cancel Subscription",
				"name-alt": "Cancel Subscription",
				"path": "/english/support/saas/cancel"
			},
			{
				"name": "Unknown charge - charge identified- disaffirm",
				"name-alt": "Unknown charge - charge identified- disaffirm",
				"path": "/english/support/saas/charge-disaffirm"
			},
			{
				"name": "Unknown charge - charge identified- not disaffirm",
				"name-alt": "Unknown charge - charge identified- not disaffirm",
				"path": "/english/support/saas/charge-not-disaffirm"
			},
			{
				"name": "Unknown charge - charge not identified",
				"name-alt": "Unknown charge - charge not identified",
				"path": "/english/support/saas/charge-not-identified"
			},
			{
				"name": "Technical or product help",
				"name-alt": "Technical or product help",
				"path": "/english/support/saas/technical"
			}
		]
	},
	"/english/support/saas/account": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/billing-authorization": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/billing-invoice": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/billing-payment": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/cancel": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/charge-disaffirm": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/charge-not-disaffirm": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/charge-not-identified": {
		"type": "results",
		"queue": "CCI-En-CS"
	},
	"/english/support/saas/technical": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/stock-individual": {
		"type": "select",
		"label": "Issue Type - Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"name-alt": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"path": "/english/support/stock-individual/account"
			},
			{
				"name": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"name-alt": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"path": "/english/support/stock-individual/billing-authorization"
			},
			{
				"name": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"name-alt": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"path": "/english/support/stock-individual/billing-invoice"
			},
			{
				"name": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"name-alt": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"path": "/english/support/stock-individual/billing-payment"
			},
			{
				"name": "Cancel Subscription - Save opportunity",
				"name-alt": "Cancel Subscription - Save opportunity",
				"path": "/english/support/stock-individual/cancel"
			},
			{
				"name": "Technical Question",
				"name-alt": "Technical Question",
				"path": "/english/support/stock-individual/technical"
			}
		]
	},
	"/english/support/stock-individual/account": {
		"type": "results",
		"queue": "TS-En-Stock"
	},
	"/english/support/stock-individual/billing-authorization": {
		"type": "results",
		"queue": "TS-En-Stock"
	},
	"/english/support/stock-individual/billing-invoice": {
		"type": "results",
		"queue": "TS-En-Stock"
	},
	"/english/support/stock-individual/billing-payment": {
		"type": "results",
		"queue": "TS-En-Stock"
	},
	"/english/support/stock-individual/cancel": {
		"type": "results",
		"queue": "RET-En-CCI"
	},
	"/english/support/stock-individual/technical": {
		"type": "results",
		"queue": "TS-En-Stock"
	},
	"/english/support/stock-team": {
		"type": "select",
		"label": "Issue Type - Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"name-alt": "Account Issues- Redemption Code, Adobe ID, Change account details, Email\\country change, all other account related question",
				"path": "/english/support/stock-team/account"
			},
			{
				"name": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"name-alt": "Billing Issues- Authorization Charge, Refund only request, other payment questions",
				"path": "/english/support/stock-team/billing-authorization"
			},
			{
				"name": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"name-alt": "Billing Issues- Invoice, TAX\\VAT, Refunds, Cancelled and refund required, refund not received",
				"path": "/english/support/stock-team/billing-invoice"
			},
			{
				"name": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"name-alt": "Billing Issues- Payment issue, payment method change, credit card update, payment failure\\issues",
				"path": "/english/support/stock-team/billing-payment"
			},
			{
				"name": "Cancel Subscription - Save opportunity",
				"name-alt": "Cancel Subscription - Save opportunity",
				"path": "/english/support/stock-team/cancel"
			},
			{
				"name": "Technical Question",
				"name-alt": "Technical Question",
				"path": "/english/support/stock-team/technical"
			}
		]
	},
	"/english/support/stock-team/account": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/stock-team/billing-authorization": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/stock-team/billing-invoice": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/stock-team/billing-payment": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/stock-team/cancel": {
		"type": "results",
		"queue": "RET-En-CCT"
	},
	"/english/support/stock-team/technical": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team": {
		"type": "select",
		"label": "Issue Type - CC Team Subscriptions",
		"label-alt": "Issue Type - CC Team Subscriptions",
		"options": [
			{
				"name": "Account management questions",
				"name-alt": "Account management questions",
				"path": "/english/support/team/account"
			},
			{
				"name": "Activation, First Launch Issues, File Sync Issues",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/english/support/team/activation"
			},
			{
				"name": "Changing the ADMIN",
				"name-alt": "Changing the ADMIN",
				"path": "/english/support/team/admin"
			},
			{
				"name": "Adobe ID",
				"name-alt": "Adobe ID",
				"path": "/english/support/team/adobe-id"
			},
			{
				"name": "Complicated Billing Questions- TAX\\VAT scenarios, etc",
				"name-alt": "Complicated Billing Questions- TAX\\VAT scenarios, etc",
				"path": "/english/support/team/billing"
			},
			{
				"name": "Cancellation \\ Reduction \\ Migration",
				"name-alt": "Cancellation \\ Reduction \\ Migration",
				"path": "/english/support/team/cancel"
			},
			{
				"name": "CCT VIP",
				"name-alt": "CCT VIP",
				"path": "/english/support/team/cct-vip"
			},
			{
				"name": "Question on charges, change of plan, payment mode change, credit card update",
				"name-alt": "Question on charges, change of plan, payment mode change, credit card update",
				"path": "/english/support/team/charges"
			},
			{
				"name": "Deployment",
				"name-alt": "Deployment",
				"path": "/english/support/team/deployment"
			},
			{
				"name": "Download, Install",
				"name-alt": "Download, Install",
				"path": "/english/support/team/download"
			},
			{
				"name": "Assign/Unassign licenses",
				"name-alt": "Assign/Unassign licenses",
				"path": "/english/support/team/licenses"
			},
			{
				"name": "Change of team name",
				"name-alt": "Change of team name",
				"path": "/english/support/team/name"
			},
			{
				"name": "Payment issue, payment failure\\issues, other simple Billing questions",
				"name-alt": "Payment issue, payment failure\\issues, other simple Billing questions",
				"path": "/english/support/team/payment"
			},
			{
				"name": "Reseller change request",
				"name-alt": "Reseller change request",
				"path": "/english/support/team/reseller"
			},
			{
				"name": "Technical Question - How To",
				"name-alt": "Technical Question - How To",
				"path": "/english/support/team/technical"
			}
		]
	},
	"/english/support/team/account": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/activation": {
		"type": "results",
		"queue": "CCT-En-Inst"
	},
	"/english/support/team/admin": {
		"type": "results",
		"queue": "CCT-En-Admin"
	},
	"/english/support/team/adobe-id": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/billing": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/cancel": {
		"type": "results",
		"queue": "RET-En-CCT"
	},
	"/english/support/team/cct-vip": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/charges": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/deployment": {
		"type": "results",
		"queue": "CCT-En-Admin"
	},
	"/english/support/team/download": {
		"type": "results",
		"queue": "CCT-En-Inst"
	},
	"/english/support/team/licenses": {
		"type": "results",
		"queue": "CCT-En-Admin"
	},
	"/english/support/team/name": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/payment": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/reseller": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/team/technical": {
		"type": "select",
		"label": "Products - CCT Team Subscriptions",
		"label-alt": "Products - CCT Team Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/english/support/team/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/english/support/team/technical/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/team/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/english/support/team/technical/illustrator"
			},
			{
				"name": "Muse, Ink and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/english/support/team/technical/muse"
			},
			{
				"name": "Other creative cloud products",
				"name-alt": "Other creative cloud products",
				"path": "/english/support/team/technical/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/english/support/team/technical/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premiere Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/english/support/team/technical/premiere"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/english/support/team/technical/sign"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/english/support/team/technical/substance"
			}
		]
	},
	"/english/support/team/technical/acrobat": {
		"type": "results",
		"queue": "CCT-En-Acro"
	},
	"/english/support/team/technical/dreamweaver": {
		"type": "results",
		"queue": "CCT-En-Web"
	},
	"/english/support/team/technical/firefly": {
		"type": "results",
		"queue": "CCT-En-Firefly"
	},
	"/english/support/team/technical/illustrator": {
		"type": "results",
		"queue": "CCT-En-Design"
	},
	"/english/support/team/technical/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/team/technical/other": {
		"type": "results",
		"message": "Consult SME or search in knowledge base",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/english/support/team/technical/photoshop": {
		"type": "results",
		"queue": "CCT-En-Photo"
	},
	"/english/support/team/technical/premiere": {
		"type": "results",
		"queue": "CCT-En-Video"
	},
	"/english/support/team/technical/sign": {
		"type": "results",
		"queue": "CCTSign-En-TS"
	},
	"/english/support/team/technical/substance": {
		"type": "results",
		"queue": "CCT-En-3D"
	},
	"/english/support/volume": {
		"type": "select",
		"label": "Issue Type-Perpetual AVL License",
		"label-alt": "Issue Type-Perpetual AVL License",
		"options": [
			{
				"name": "Account management",
				"name-alt": "Account management",
				"path": "/english/support/volume/account"
			},
			{
				"name": "Activation or Billing",
				"name-alt": "Activation or Billing",
				"path": "/english/support/volume/activation"
			},
			{
				"name": "Download, Install",
				"name-alt": "Download, Install",
				"path": "/english/support/volume/download"
			},
			{
				"name": "Return and Refund",
				"name-alt": "Return and Refund",
				"path": "/english/support/volume/return"
			},
			{
				"name": "Serial number Lost or missing",
				"name-alt": "Serial number Lost or missing",
				"path": "/english/support/volume/serial-number-lost"
			},
			{
				"name": "Serial number not working",
				"name-alt": "Serial number not working",
				"path": "/english/support/volume/serial-number-not-working"
			},
			{
				"name": "Technical- How To",
				"name-alt": "Technical- How To",
				"path": "/english/support/volume/technical"
			}
		]
	},
	"/english/support/volume/account": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/volume/activation": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/volume/download": {
		"type": "results",
		"queue": "CCT-En-Inst"
	},
	"/english/support/volume/return": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/volume/serial-number-lost": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/volume/serial-number-not-working": {
		"type": "results",
		"queue": "CCT-En-CS"
	},
	"/english/support/volume/technical": {
		"type": "select",
		"label": "Products - Perpetual AVL- Volume License",
		"label-alt": "Products - Perpetual AVL- Volume License",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"path": "/english/support/volume/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate",
				"name-alt": "Dreamweaver, Animate",
				"path": "/english/support/volume/technical/dreamweaver"
			},
			{
				"name": "Photoshop Elements or Premiere Elements 20, 21, 22",
				"name-alt": "Photoshop Elements or Premiere Elements 20, 21, 22",
				"path": "/english/support/volume/technical/elements"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/english/support/volume/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture",
				"name-alt": "Illustrator, Indesign, Incopy, Capture",
				"path": "/english/support/volume/technical/illustrator"
			},
			{
				"name": "Muse",
				"name-alt": "Muse",
				"path": "/english/support/volume/technical/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/english/support/volume/technical/photoshop"
			},
			{
				"name": "Premiere Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premiere Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/english/support/volume/technical/premiere"
			}
		]
	},
	"/english/support/volume/technical/acrobat": {
		"type": "results",
		"queue": "CCT-En-Acro"
	},
	"/english/support/volume/technical/dreamweaver": {
		"type": "results",
		"queue": "CCT-En-Web"
	},
	"/english/support/volume/technical/elements": {
		"type": "results",
		"queue": "TS-En-Pepe"
	},
	"/english/support/volume/technical/firefly": {
		"type": "results",
		"queue": "TS-En-Firefly"
	},
	"/english/support/volume/technical/illustrator": {
		"type": "results",
		"queue": "CCT-En-Design"
	},
	"/english/support/volume/technical/muse": {
		"type": "results",
		"message": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://forums.adobe.com/welcome\">Forum Link</a>"
	},
	"/english/support/volume/technical/photoshop": {
		"type": "results",
		"queue": "CCT-En-Photo"
	},
	"/english/support/volume/technical/premiere": {
		"type": "results",
		"queue": "CCT-En-Video"
	},
	"/english/sales": {
		"type": "select",
		"label": "License Customer has sales query for?",
		"label-alt": "License Customer has sales query for?",
		"options": [
			{
				"name": "Enterprise (EVIP\\ETLA)",
				"name-alt": "Enterprise (EVIP\\ETLA)",
				"path": "/english/sales/enterprise"
			},
			{
				"name": "Individual",
				"name-alt": "Individual",
				"path": "/english/sales/individual"
			},
			{
				"name": "Team",
				"name-alt": "Team",
				"path": "/english/sales/team"
			},
			{
				"name": "Sign (Ind, Team, Ent)",
				"name-alt": "Sign (Ind, Team, Ent)",
				"path": "/english/sales/sign"
			},
			{
				"name": "Stock (Ind, Team, Ent)",
				"name-alt": "Stock (Ind, Team, Ent)",
				"path": "/english/sales/stock"
			},
			{
				"name": "Account Manager",
				"name-alt": "Account Manager",
				"path": "/english/sales/account-manager"
			},
			{
				"name": "Adobe Express",
				"name-alt": "Adobe Express",
				"path": "/english/sales/adobe-express"
			}
		]
	},
	"/english/sales/enterprise": {
		"type": "results",
		"message": "No queue to transfer. Explain the following to the customer: For details on buying Enterprise licenses, please visit \n<a target=\"_blank\" href=\"https://www.adobe.com/howtobuy/buying-programs/enterprise.html\">https://www.adobe.com/howtobuy/buying-programs/enterprise.html</a> or fill the consultation form at \n<a target=\"_blank\" href=\"https://www.adobe.com/products/request-consultation/creative-cloud.html\"></a>",
		"message-alt": "No queue to transfer. Explain the following to the customer: For details on buying Enterprise licenses, please visit \n<a target=\"_blank\" href=\"https://www.adobe.com/howtobuy/buying-programs/enterprise.html\">https://www.adobe.com/howtobuy/buying-programs/enterprise.html</a> or fill the consultation form at \n<a target=\"_blank\" href=\"https://www.adobe.com/products/request-consultation/creative-cloud.html\"></a>"
	},
	"/english/sales/individual": {
		"type": "select",
		"label": "Geo",
		"label-alt": "Geo",
		"options": [
			{
				"name": "AMR",
				"name-alt": "AMR",
				"path": "/english/sales/individual/amr"
			},
			{
				"name": "EMEA",
				"name-alt": "EMEA",
				"path": "/english/sales/individual/emea"
			},
			{
				"name": "APAC",
				"name-alt": "APAC",
				"path": "/english/sales/individual/apac"
			}
		]
	},
	"/english/sales/individual/amr": {
		"type": "results",
		"queue": "SLS-CCI-En-NA"
	},
	"/english/sales/individual/emea": {
		"type": "results",
		"queue": "SLS-CCI-En-EMEA"
	},
	"/english/sales/individual/apac": {
		"type": "results",
		"queue": "SLS-CCI-En-APAC"
	},
	"/english/sales/team": {
		"type": "select",
		"label": "Geo",
		"label-alt": "Geo",
		"options": [
			{
				"name": "AMR",
				"name-alt": "AMR",
				"path": "/english/sales/team/amr"
			},
			{
				"name": "EMEA",
				"name-alt": "EMEA",
				"path": "/english/sales/team/emea"
			},
			{
				"name": "APAC",
				"name-alt": "APAC",
				"path": "/english/sales/team/apac"
			}
		]
	},
	"/english/sales/team/amr": {
		"type": "results",
		"queue": "SLS-CCT-En-NA"
	},
	"/english/sales/team/emea": {
		"type": "results",
		"queue": "SLS-CCT-En-EMEA"
	},
	"/english/sales/team/apac": {
		"type": "results",
		"queue": "SLS-CCT-En-APAC"
	},
	"/english/sales/sign": {
		"type": "select",
		"label": "Geo",
		"label-alt": "Geo",
		"options": [
			{
				"name": "AMR",
				"name-alt": "AMR",
				"path": "/english/sales/sign/amr"
			},
			{
				"name": "EMEA",
				"name-alt": "EMEA",
				"path": "/english/sales/sign/emea"
			},
			{
				"name": "APAC",
				"name-alt": "APAC",
				"path": "/english/sales/sign/apac"
			}
		]
	},
	"/english/sales/sign/amr": {
		"type": "filter",
		"label": "Channel",
		"label-alt": "Channel",
		"options": [
			{
				"name": "Chat",
				"name-alt": "Chat",
				"path": "/english/sales/sign/amr/chat"
			},
			{
				"name": "Voice",
				"name-alt": "Voice",
				"path": "/english/sales/sign/amr/voice"
			}
		]
	},
	"/english/sales/sign/amr/chat": {
		"type": "results",
		"queue": "SLS-DC-En-NA"
	},
	"/english/sales/sign/amr/voice": {
		"type": "select",
		"label": "Team or Individual License?",
		"label-alt": "Team or Individual License?",
		"options": [
			{
				"name": "Individual",
				"name-alt": "Individual",
				"path": "/english/sales/sign/amr/voice/individual"
			},
			{
				"name": "Team",
				"name-alt": "Team",
				"path": "/english/sales/sign/amr/voice/team"
			}
		]
	},
	"/english/sales/sign/amr/voice/individual": {
		"type": "results",
		"queue": "SLS-DCI-En-NA"
	},
	"/english/sales/sign/amr/voice/team": {
		"type": "results",
		"queue": "SLS-DCT-En-NA"
	},
	"/english/sales/sign/emea": {
		"type": "results",
		"queue": "SLS-DC-En-EMEA"
	},
	"/english/sales/sign/apac": {
		"type": "results",
		"queue": "SLS-DC-En-APAC"
	},
	"/english/sales/stock": {
		"type": "select",
		"label": "Geo",
		"label-alt": "Geo",
		"options": [
			{
				"name": "AMR",
				"name-alt": "AMR",
				"path": "/english/sales/stock/amr"
			},
			{
				"name": "EMEA",
				"name-alt": "EMEA",
				"path": "/english/sales/stock/emea"
			},
			{
				"name": "APAC",
				"name-alt": "APAC",
				"path": "/english/sales/stock/apac"
			}
		]
	},
	"/english/sales/stock/amr": {
		"type": "results",
		"queue": "SLS-STOCK-En-NA"
	},
	"/english/sales/stock/emea": {
		"type": "results",
		"queue": "SLS-STOCK-En-EMEA"
	},
	"/english/sales/stock/apac": {
		"type": "results",
		"queue": "SLS-STOCK-En-APAC"
	},
	"/english/sales/account-manager": {
		"type": "results",
		"queue": "SLS-AM-En-NA"
	},
	"/english/partner-program": {
		"type": "select",
		"label": "Programs",
		"label-alt": "Programs",
		"options": [
			{
				"name": "Reseller has contacted (Unable to place Orders, remove license for customer, return order issue, reseller console issue, pricing issues)",
				"name-alt": "Reseller has contacted (Unable to place Orders, remove license for customer, return order issue, reseller console issue, pricing issues)",
				"path": "/english/partner-program/reseller"
			},
			{
				"name": "TPP",
				"name-alt": "TPP",
				"path": "/english/partner-program/tpp"
			},
			{
				"name": "SPP",
				"name-alt": "SPP",
				"path": "/english/partner-program/spp"
			}
		]
	},
	"/english/partner-program/reseller": {
		"type": "results",
		"queue": "DNL-APC-En-PHD"
	},
	"/english/partner-program/tpp": {
		"type": "results",
		"queue": "DNL-TPP-HD-En"
	},
	"/english/partner-program/spp": {
		"type": "results",
		"queue": "DNL-SPP-HD-En"
	},
	"/english/sales/adobe-express": {
		"type": "select",
		"label": "Geo",
		"label-alt": "Geo",
		"options": [
			{
				"name": "AMR",
				"name-alt": "AMR",
				"path": "/english/sales/adobe-express/amr"
			},
			{
				"name": "EMEA",
				"name-alt": "EMEA",
				"path": "/english/sales/adobe-express/emea"
			},
			{
				"name": "APAC",
				"name-alt": "APAC",
				"path": "/english/sales/adobe-express/apac"
			}
		]
	},
	"/english/sales/adobe-express/amr": {
		"type": "results",
		"queue": "SLS-Express-En-NA"
	},
	"/english/sales/adobe-express/emea": {
		"type": "results",
		"queue": "SLS-Express-En-EMEA"
	},
	"/english/sales/adobe-express/apac": {
		"type": "results",
		"queue": "SLS-Express-En-APAC"
	}
}