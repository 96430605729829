export default {
	"/": {
		"type": "select",
		"label": "문의내용",
		"label-alt": "Nature of Contact",
		"options": [
			{
				"name": "CS, TS, RET 관련 문의",
				"name-alt": "Support for CS, TS, Ret issues",
				"path": "/support"
			},
			{
				"name": "판매 문의 (가격, 플랜 등)",
				"name-alt": "Sales query (Pricing, plans, etc)",
				"path": "/sales"
			}
		]
	},
	"/support": {
		"type": "select",
		"label": "고객의 라이선스 종류",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "엔터프라이즈 (EVIP 및 ETLA)",
				"name-alt": "Enterprise (EVIP and ETLA)",
				"path": "/support/enterprise"
			},
			{
				"name": "개인 구독",
				"name-alt": "Individual Subscription",
				"path": "/support/individual"
			},
			{
				"name": "영구 개인 라이선스",
				"name-alt": "Perpetual Individual License",
				"path": "/support/perpetual"
			},
			{
				"name": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"name-alt": "PPBU(Captivate and Captivate Prime, Cold Fusion, FrameMaker, FrameMaker Publishing Server, RoboHelp, RoboHelp Server, Presenter, Presenter Video Express)",
				"path": "/support/ppbu"
			},
			{
				"name": "SAAS - Export PDF, PDF Pack",
				"name-alt": "SAAS - Export PDF, PDF Pack",
				"path": "/support/saas"
			},
			{
				"name": "Stock for Individual",
				"name-alt": "Stock for Individual",
				"path": "/support/stock-individual"
			},
			{
				"name": "Stock for Team",
				"name-alt": "Stock for Team",
				"path": "/support/stock-team"
			},
			{
				"name": "팀 구독",
				"name-alt": "Team Subscription",
				"path": "/support/team"
			},
			{
				"name": "볼륨 라이선스 - AVL 영구 라이선스",
				"name-alt": "Volume License - AVL Perpetual",
				"path": "/support/volume"
			}
		]
	},
	"/support/enterprise": {
		"type": "select",
		"label": "ENT 고객 프로필",
		"label-alt": "Profile of ENT Customer",
		"options": [
			{
				"name": "관리자",
				"name-alt": "Administrator",
				"path": "/support/enterprise/admin"
			},
			{
				"name": "ASP 온쇼어 ",
				"name-alt": "ASP Onshore ",
				"path": "/support/enterprise/asp-onshore"
			},
			{
				"name": "ASP 서비스 데스크",
				"name-alt": "ASP Service Desk",
				"path": "/support/enterprise/asp-service-desk"
			},
			{
				"name": "ASP 세미 ",
				"name-alt": "ASP SME ",
				"path": "/support/enterprise/asp-sme"
			},
			{
				"name": "엔드 유저",
				"name-alt": "End User",
				"path": "/support/enterprise/end-user"
			}
		]
	},
	"/support/enterprise/admin": {
		"type": "results",
		"queue": "ENT-Uni-SD-Ko"
	},
	"/support/enterprise/asp-onshore": {
		"type": "results",
		"queue": "ENT-ASP-Ko-APAC-Onshore"
	},
	"/support/enterprise/asp-service-desk": {
		"type": "results",
		"queue": "ENT-ASP-Ko-SD"
	},
	"/support/enterprise/asp-sme": {
		"type": "results",
		"queue": "ENT-ASP-Ko-SME"
	},
	"/support/enterprise/end-user": {
		"type": "results",
		"message": "고객에게 해당 IT 관리자 또는 Adobe 계정 관리자에게 문의하여 관리 콘솔을 통해 문의하도록 안내하십시오.",
		"message-alt": "Advise customer to speak to their respective IT admin or Adobe account administrator to contact us via the admin console"
	},
	"/support/individual": {
		"type": "select",
		"label": "문제 유형 - 개인 구독",
		"label-alt": "Issue Type - Individual Subscriptions",
		"options": [
			{
				"name": "계정 문제",
				"name-alt": "Account Issues",
				"path": "/support/individual/account"
			},
			{
				"name": "빌링 문제",
				"name-alt": "Billing Issues",
				"path": "/support/individual/billing"
			},
			{
				"name": "구독 취소",
				"name-alt": "Cancel Subscription",
				"path": "/support/individual/cancel"
			},
			{
				"name": "알 수 없는 청구",
				"name-alt": "Unknown charge",
				"path": "/support/individual/charge"
			},
			{
				"name": "기술적인 문제 또는 제품 지원",
				"name-alt": "Technical or product help",
				"path": "/support/individual/technical"
			},
			{
				"name": "시리얼 번호/Redemption code가 막힘",
				"name-alt": "Revoked \\ Blacklisted Redemption Code",
				"path": "/support/individual/revoked"
			}
		]
	},
	"/support/individual/account": {
		"type": "results",
		"queue": "CCM-Ko-Uni"
	},
	"/support/individual/billing": {
		"type": "results",
		"queue": "CCM-Ko-CS"
	},
	"/support/individual/cancel": {
		"type": "results",
		"queue": "RET-KoPriority-CCI"
	},
	"/support/individual/charge": {
		"type": "results",
		"queue": "RET-KoPriority-CCI"
	},
	"/support/individual/technical": {
		"type": "select",
		"label": "문제 유형 - 개인 구독",
		"label-alt": "Products- Individual Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/individual/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/individual/technical/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/individual/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/individual/technical/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/support/individual/technical/muse"
			},
			{
				"name": "기타 크리에이티브 클라우드 제품",
				"name-alt": "Other creative cloud products",
				"path": "/support/individual/technical/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/individual/technical/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/individual/technical/premier"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/individual/technical/substance"
			}
		]
	},
	"/support/individual/technical/acrobat": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/dreamweaver": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/firefly": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/illustrator": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/muse": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/individual/technical/other": {
		"type": "results",
		"message": "SME에 문의하거나 지식 베이스에서 검색",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/individual/technical/photoshop": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/premier": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/technical/substance": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/individual/revoked": {
		"type": "results",
		"queue": "AG-Ko-CC"
	},
	"/support/perpetual": {
		"type": "select",
		"label": "문제 유형 - 영구 AVL 라이선스",
		"label-alt": "Issue Type - Perpetual AVL License",
		"options": [
			{
				"name": "계정 관리",
				"name-alt": "Account management",
				"path": "/support/perpetual/account"
			},
			{
				"name": "시리얼 번호가 정품이 아니에요.",
				"name-alt": "Serial number not Genuine error",
				"path": "/support/perpetual/serial-number-not-genuine"
			},
			{
				"name": "기술적인 질문 - 방법에 대해",
				"name-alt": "Technical Question - How To",
				"path": "/support/perpetual/technical"
			}
		]
	},
	"/support/perpetual/account": {
		"type": "select",
		"label": "영구 개인 제품",
		"label-alt": "Perpetual Individual Products",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Standard 2020,2017 desktop or Acrobat Pro 2020, 2017 desktop",
				"path": "/support/perpetual/account/acrobat"
			},
			{
				"name": "Lightroom 6",
				"name-alt": "Lightroom 6",
				"path": "/support/perpetual/account/lightroom"
			},
			{
				"name": "여기에 나열되지 않은 기타 영구 제품",
				"name-alt": "Any other perpetual product not listed here",
				"path": "/support/perpetual/account/other"
			},
			{
				"name": "Photoshop Elements 20, 21, 22",
				"name-alt": "Photoshop Elements 20, 21, 22",
				"path": "/support/perpetual/account/photoshop"
			},
			{
				"name": "Premier Elements 20, 21, 22",
				"name-alt": "Premier Elements 20, 21, 22",
				"path": "/support/perpetual/account/premier"
			}
		]
	},
	"/support/perpetual/account/acrobat": {
		"type": "results",
		"queue": "PPTL-Ko-Uni"
	},
	"/support/perpetual/account/lightroom": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/perpetual/account/other": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/perpetual/account/photoshop": {
		"type": "results",
		"queue": "PPTL-Ko-Uni"
	},
	"/support/perpetual/account/premier": {
		"type": "results",
		"queue": "PPTL-Ko-Uni"
	},
	"/support/perpetual/serial-number-not-genuine": {
		"type": "results",
		"queue": "AG-Ko-CC"
	},
	"/support/perpetual/technical": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/ppbu": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/saas": {
		"type": "select",
		"label": "이슈 유형 - SAAS",
		"label-alt": "Issue Type - SAAS",
		"options": [
			{
				"name": "계정 문제",
				"name-alt": "Account Issues",
				"path": "/support/saas/account"
			},
			{
				"name": "빌링 문제",
				"name-alt": "Billing Issues",
				"path": "/support/saas/billing"
			},
			{
				"name": "구독 취소",
				"name-alt": "Cancel Subscription",
				"path": "/support/saas/cancel"
			},
			{
				"name": "알 수 없는 청구",
				"name-alt": "Unknown charge",
				"path": "/support/saas/charge"
			},
			{
				"name": "기술적인 문제 또는 제품 지원",
				"name-alt": "Technical or product help",
				"path": "/support/saas/technical"
			}
		]
	},
	"/support/saas/account": {
		"type": "results",
		"queue": "CCM-Ko-Uni"
	},
	"/support/saas/billing": {
		"type": "results",
		"queue": "CCM-Ko-Uni"
	},
	"/support/saas/cancel": {
		"type": "results",
		"queue": "RET-KoPriority-CCI"
	},
	"/support/saas/charge": {
		"type": "results",
		"queue": "RET-KoPriority-CCI"
	},
	"/support/saas/technical": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/stock-individual": {
		"type": "select",
		"label": "이슈 유형 - Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "계정 문제",
				"name-alt": "Account Issues",
				"path": "/support/stock-individual/account"
			},
			{
				"name": "빌링 문제",
				"name-alt": "Billing Issues",
				"path": "/support/stock-individual/billing"
			},
			{
				"name": "구독 취소",
				"name-alt": "Cancel Subscription",
				"path": "/support/stock-individual/cancel"
			},
			{
				"name": "기술적인 질문 - 방법에 대해",
				"name-alt": "Technical Question - How To",
				"path": "/support/stock-individual/technical"
			}
		]
	},
	"/support/stock-individual/account": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/stock-individual/billing": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/stock-individual/cancel": {
		"type": "results",
		"queue": "RET-KoPriority-CCI"
	},
	"/support/stock-individual/technical": {
		"type": "results",
		"queue": "CCM-Ko-TS"
	},
	"/support/stock-team": {
		"type": "select",
		"label": "이슈 유형 - Stock",
		"label-alt": "Issue Type - Stock",
		"options": [
			{
				"name": "계정 문제",
				"name-alt": "Account Issues",
				"path": "/support/stock-team/account"
			},
			{
				"name": "빌링 문제",
				"name-alt": "Billing Issues",
				"path": "/support/stock-team/billing"
			},
			{
				"name": "구독 취소",
				"name-alt": "Cancel Subscription",
				"path": "/support/stock-team/cancel"
			},
			{
				"name": "기술적인 질문 - 방법에 대해",
				"name-alt": "Technical Question - How To",
				"path": "/support/stock-team/technical"
			}
		]
	},
	"/support/stock-team/account": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/stock-team/account/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/stock-team/account/voice"
			}
		]
	},
	"/support/stock-team/account/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/stock-team/account/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/stock-team/billing": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/stock-team/billing/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/stock-team/billing/voice"
			}
		]
	},
	"/support/stock-team/billing/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/stock-team/billing/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/stock-team/cancel": {
		"type": "results",
		"queue": "RET-Ko-CCT"
	},
	"/support/stock-team/technical": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/stock-team/technical/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/stock-team/technical/voice"
			}
		]
	},
	"/support/stock-team/technical/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/stock-team/technical/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team": {
		"type": "select",
		"label": "이슈 유형 - CCTeam 구독",
		"label-alt": "Issue Type - CCTeam Subscriptions",
		"options": [
			{
				"name": "계정 관리",
				"name-alt": "Account management",
				"path": "/support/team/account"
			},
			{
				"name": "활성화, 첫 실행 문제, 파일 동기화 문제",
				"name-alt": "Activation, First Launch Issues, File Sync Issues",
				"path": "/support/team/activation"
			},
			{
				"name": "관리자 변경",
				"name-alt": "Changing the ADMIN",
				"path": "/support/team/admin"
			},
			{
				"name": "Adobe ID",
				"name-alt": "Adobe ID",
				"path": "/support/team/adobe-id"
			},
			{
				"name": "복잡한 청구 질문- 세금\\ VAT 관련 문의",
				"name-alt": "Complicated Billing Questions- TAX\\VAT scenarios, etc",
				"path": "/support/team/billing"
			},
			{
				"name": "구독 취소",
				"name-alt": "Cancel Subscription",
				"path": "/support/team/cancel"
			},
			{
				"name": "CCT VIP",
				"name-alt": "CCT VIP",
				"path": "/support/team/cct-vip"
			},
			{
				"name": "요금, 요금제 변경, 결제 방식 변경, 신용카드 업데이트 관련 질문",
				"name-alt": "Question on charges, change of plan, payment mode change, credit card update",
				"path": "/support/team/charges"
			},
			{
				"name": "배포",
				"name-alt": "Deployment",
				"path": "/support/team/deployment"
			},
			{
				"name": "다운로드, 설치",
				"name-alt": "Download, Install",
				"path": "/support/team/download"
			},
			{
				"name": "라이선스 할당/할당 취소",
				"name-alt": "Assign/Unassign licenses",
				"path": "/support/team/licenses"
			},
			{
				"name": "팀 이름 변경",
				"name-alt": "Change of team name",
				"path": "/support/team/name"
			},
			{
				"name": "결제 문제, 결제 실패\\ 문제, 기타 단순 청구 질문",
				"name-alt": "Payment issue, payment failure\\issues, other simple Billing questions",
				"path": "/support/team/payment"
			},
			{
				"name": "리셀러 변경 요청",
				"name-alt": "Reseller change request",
				"path": "/support/team/reseller"
			},
			{
				"name": "기술적인 질문 - 방법에 대해",
				"name-alt": "Technical Question - How To",
				"path": "/support/team/technical"
			}
		]
	},
	"/support/team/account": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/account/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/account/voice"
			}
		]
	},
	"/support/team/account/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/account/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/activation": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/activation/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/activation/voice"
			}
		]
	},
	"/support/team/activation/chat": {
		"type": "results",
		"queue": "CCT-Ko-Inst"
	},
	"/support/team/activation/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/admin": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/adobe-id": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/adobe-id/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/adobe-id/voice"
			}
		]
	},
	"/support/team/adobe-id/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/adobe-id/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/billing": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/billing/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/billing/voice"
			}
		]
	},
	"/support/team/billing/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/billing/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/cancel": {
		"type": "results",
		"queue": "RET-Ko-CCT"
	},
	"/support/team/cct-vip": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/cct-vip/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/cct-vip/voice"
			}
		]
	},
	"/support/team/cct-vip/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/cct-vip/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/charges": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/charges/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/charges/voice"
			}
		]
	},
	"/support/team/charges/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/charges/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/deployment": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/download": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/download/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/download/voice"
			}
		]
	},
	"/support/team/download/chat": {
		"type": "results",
		"queue": "CCT-Ko-Inst"
	},
	"/support/team/download/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/licenses": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/name": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/name/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/name/voice"
			}
		]
	},
	"/support/team/name/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/name/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/payment": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/payment/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/payment/voice"
			}
		]
	},
	"/support/team/payment/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/payment/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/reseller": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/reseller/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/reseller/voice"
			}
		]
	},
	"/support/team/reseller/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/reseller/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/team/technical": {
		"type": "select",
		"label": "제품 - CCTeam 구독",
		"label-alt": "Products - CCTeam Subscriptions",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC",
				"path": "/support/team/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate, Adobe Express, XD",
				"name-alt": "Dreamweaver, Animate, Adobe Express, XD",
				"path": "/support/team/technical/dreamweaver"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/team/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"name-alt": "Illustrator, Indesign, Incopy, Capture, Comp (M), Illustrator Draw (M)",
				"path": "/support/team/technical/illustrator"
			},
			{
				"name": "Muse, Ink, and Slide",
				"name-alt": "Muse, Ink and Slide",
				"path": "/support/team/technical/muse"
			},
			{
				"name": "기타 크리에이티브 클라우드 제품",
				"name-alt": "Other creative cloud products",
				"path": "/support/team/technical/other"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom(M), Lightroom Standard, Bridge, Camera Raw, Dimension",
				"path": "/support/team/technical/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Rush, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/team/technical/premier"
			},
			{
				"name": "Sign",
				"name-alt": "Sign",
				"path": "/support/team/technical/sign"
			},
			{
				"name": "Substance 3D Products",
				"name-alt": "Substance 3D Products",
				"path": "/support/team/technical/substance"
			}
		]
	},
	"/support/team/technical/acrobat": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/acrobat/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/acrobat/voice"
			}
		]
	},
	"/support/team/technical/acrobat/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/acrobat/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/dreamweaver": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/dreamweaver/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/dreamweaver/voice"
			}
		]
	},
	"/support/team/technical/dreamweaver/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/dreamweaver/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/firefly": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/firefly/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/firefly/voice"
			}
		]
	},
	"/support/team/technical/firefly/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/firefly/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/illustrator": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/illustrator/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/illustrator/voice"
			}
		]
	},
	"/support/team/technical/illustrator/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/illustrator/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/muse": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/team/technical/other": {
		"type": "results",
		"message": "SME에 문의하거나 지식 베이스에서 검색",
		"message-alt": "Consult SME or search in knowledge base"
	},
	"/support/team/technical/photoshop": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/photoshop/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/photoshop/voice"
			}
		]
	},
	"/support/team/technical/photoshop/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/photoshop/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/premier": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/premier/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/premier/voice"
			}
		]
	},
	"/support/team/technical/premier/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/premier/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/sign": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/sign/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/sign/voice"
			}
		]
	},
	"/support/team/technical/sign/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/sign/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/team/technical/substance": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/team/technical/substance/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/team/technical/substance/voice"
			}
		]
	},
	"/support/team/technical/substance/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/team/technical/substance/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume": {
		"type": "select",
		"label": "문제 유형 - 영구 AVL 라이선스",
		"label-alt": "Issue Type - Perpetual AVL License",
		"options": [
			{
				"name": "계정 관리",
				"name-alt": "Account management",
				"path": "/support/volume/account"
			},
			{
				"name": "활성화 또는 빌링",
				"name-alt": "Activation or Billing",
				"path": "/support/volume/activation"
			},
			{
				"name": "다운로드, 설치",
				"name-alt": "Download, Install",
				"path": "/support/volume/download"
			},
			{
				"name": "반품 및 환불",
				"name-alt": "Return and Refund",
				"path": "/support/volume/return"
			},
			{
				"name": "시리얼 번호 분실 또는 누락",
				"name-alt": "Serial number Lost or missing",
				"path": "/support/volume/serial-number-lost"
			},
			{
				"name": "시리얼 번호가 작동하지 않아요",
				"name-alt": "Serial number not working",
				"path": "/support/volume/serial-number-not-working"
			},
			{
				"name": "기술적인 질문 - 방법에 대해",
				"name-alt": "Technical Question - How To",
				"path": "/support/volume/technical"
			}
		]
	},
	"/support/volume/account": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/account/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/account/voice"
			}
		]
	},
	"/support/volume/account/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/account/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/volume/activation": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/activation/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/activation/voice"
			}
		]
	},
	"/support/volume/activation/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/activation/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/volume/download": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/download/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/download/voice"
			}
		]
	},
	"/support/volume/download/chat": {
		"type": "results",
		"queue": "CCT-Ko-Inst"
	},
	"/support/volume/download/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/return": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/return/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/return/voice"
			}
		]
	},
	"/support/volume/return/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/return/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/volume/serial-number-lost": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/serial-number-lost/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/serial-number-lost/voice"
			}
		]
	},
	"/support/volume/serial-number-lost/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/serial-number-lost/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/volume/serial-number-not-working": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/serial-number-not-working/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/serial-number-not-working/voice"
			}
		]
	},
	"/support/volume/serial-number-not-working/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/serial-number-not-working/voice": {
		"type": "results",
		"queue": "CCT-Ko-Admin"
	},
	"/support/volume/technical": {
		"type": "select",
		"label": "제품 - 영구 AVL- 볼륨 라이선스",
		"label-alt": "Products - Perpetual AVL- Volume License",
		"options": [
			{
				"name": "Acrobat Pro DC or Acrobat Standard DC",
				"name-alt": "Acrobat Pro DC or Acrobat Standard DC or Acrobat Desktop",
				"path": "/support/volume/technical/acrobat"
			},
			{
				"name": "Dreamweaver, Animate",
				"name-alt": "Dreamweaver, Animate",
				"path": "/support/volume/technical/dreamweaver"
			},
			{
				"name": "Photoshop Elements or Premier Elements 20, 21, 22",
				"name-alt": "Photoshop Elements or Premier Elements 20, 21, 22",
				"path": "/support/volume/technical/elements"
			},
			{
				"name": "Firefly",
				"name-alt": "Firefly",
				"path": "/support/volume/technical/firefly"
			},
			{
				"name": "Illustrator, Indesign, Incopy, Capture",
				"name-alt": "Illustrator, Indesign, Incopy, Capture",
				"path": "/support/volume/technical/illustrator"
			},
			{
				"name": "Muse",
				"name-alt": "Muse",
				"path": "/support/volume/technical/muse"
			},
			{
				"name": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"name-alt": "Photoshop, Lightroom, Lightroom Standard, Bridge, Camera Raw,  Dimension",
				"path": "/support/volume/technical/photoshop"
			},
			{
				"name": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"name-alt": "Premier Pro, After Effects, Prelude, Audition, Character Animator, Encore, Media encoder",
				"path": "/support/volume/technical/premier"
			}
		]
	},
	"/support/volume/technical/acrobat": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/acrobat/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/acrobat/voice"
			}
		]
	},
	"/support/volume/technical/acrobat/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/acrobat/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/dreamweaver": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/dreamweaver/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/dreamweaver/voice"
			}
		]
	},
	"/support/volume/technical/dreamweaver/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/dreamweaver/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/elements": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/elements/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/elements/voice"
			}
		]
	},
	"/support/volume/technical/elements/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/elements/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/firefly": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/firefly/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/firefly/voice"
			}
		]
	},
	"/support/volume/technical/firefly/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/firefly/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/illustrator": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/illustrator/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/illustrator/voice"
			}
		]
	},
	"/support/volume/technical/illustrator/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/illustrator/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/muse": {
		"type": "results",
		"message": "제품이 EOL이며 커뮤니티 포럼에서 문제를 지원한다는 점을 고객에게 알립니다. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">커뮤니티 링크</a>",
		"message-alt": "Educate the customer that the product is EOL and the issue is supported on the community forum. \n<a target=\"_blank\" href=\"https://community.adobe.com/t5/ko/ct-p/ko?profile.language=ko\">Forum Link</a>"
	},
	"/support/volume/technical/photoshop": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/photoshop/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/photoshop/voice"
			}
		]
	},
	"/support/volume/technical/photoshop/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/photoshop/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/support/volume/technical/premier": {
		"type": "filter",
		"label": "채널",
		"label-alt": "Channel",
		"options": [
			{
				"name": "채팅",
				"name-alt": "Chat",
				"path": "/support/volume/technical/premier/chat"
			},
			{
				"name": "전화",
				"name-alt": "Voice",
				"path": "/support/volume/technical/premier/voice"
			}
		]
	},
	"/support/volume/technical/premier/chat": {
		"type": "results",
		"queue": "CCT-Ko-Uni"
	},
	"/support/volume/technical/premier/voice": {
		"type": "results",
		"queue": "CCT-Ko-TS"
	},
	"/sales": {
		"type": "select",
		"label": "고객이 어떤 라이선스를 요청하나요?",
		"label-alt": "Customer calling for which license?",
		"options": [
			{
				"name": "개인",
				"name-alt": "Individual",
				"path": "/sales/individual"
			},
			{
				"name": "팀 구독",
				"name-alt": "Team Subscription",
				"path": "/sales/team"
			}
		]
	},
	"/sales/individual": {
		"type": "results",
		"queue": "SLS-CCI-Ko"
	},
	"/sales/team": {
		"type": "results",
		"queue": "SLS-CCT-Ko"
	}
}