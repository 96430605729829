const localization: any = {
  en: {
    AGENT_POPOVER: {
      AGENT_NAME: 'Agent Name',
      AGENT_NICKNAME: 'Agent nickname',
      AGENT_ACCOUNT: 'Account #',
      DARK_MODE: 'Dark mode',
      SOUND: 'Sound',
      LOG_OUT: 'LOG OUT',
      STATUS: 'STATUS',
      SETTINGS: 'SETTINGS',
      MY_DETAILS: 'MY DETAILS',
      LANGUAGE: 'Language',
      RINGER_DEVICE: 'Ringer Device'
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Current Conversations',
      ALL_CONVERSATIONS_TOOL_TIP: 'Previous Conversations',
      HELP_TOOL_TIP: 'Help',
      AGENT_CSAT_TOOL_TIP: 'Agent CSAT',
      AGENT_STATUS_TOOL_TIP: 'Agent Status',
      AGENT_STATUS_CURRENT : 'Current',
      AGENT_STATUS_NEXT : 'Next',
      TRANSLATE_TOOL_TIP: 'Translate'
    },
    LOGOUT: {
      END_USER_SESSION: 'End User Session',
      ARE_SURE_WANT_TO_LOG_OUT: 'Are you sure you want to log out?',
      CANCEL: 'Cancel',
      LOG_OUT: 'Log Out'
    }
  },
  ja: {
    AGENT_POPOVER: {
      AGENT_NAME: '担当者名',
      AGENT_NICKNAME: '担当者の表示名',
      AGENT_ACCOUNT: 'アカウント #',
      DARK_MODE: 'ダークモード',
      SOUND: '音',
      LOG_OUT: 'ログアウト',
      STATUS: 'ステータス',
      SETTINGS: '設定',
      MY_DETAILS: '詳細',
      LANGUAGE: '言語',
      RINGER_DEVICE: '着信音デバイス'
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: '現在の会話',
      ALL_CONVERSATIONS_TOOL_TIP: '以前の会話',
      HELP_TOOL_TIP: 'ヘルプ',
      AGENT_CSAT_TOOL_TIP: '担当者の CSAT',
      AGENT_STATUS_TOOL_TIP: '担当者のステータス',
      AGENT_STATUS_CURRENT : '現在',
      AGENT_STATUS_NEXT : '次へ',
      TRANSLATE_TOOL_TIP: '翻訳'
    },
    LOGOUT: {
      END_USER_SESSION: 'ユーザーセッションを終了',
      ARE_SURE_WANT_TO_LOG_OUT: 'ログオフしますか？',
      CANCEL: 'キャンセル',
      LOG_OUT: 'ログアウト'
    }
  },
  fr: {
    AGENT_POPOVER: {
      AGENT_NAME: 'Nom de l’agent',
      AGENT_NICKNAME: 'Surnom de l’agent',
      AGENT_ACCOUNT: 'Numéro de compte',
      DARK_MODE: 'Mode sombre',
      SOUND: 'Son',
      LOG_OUT: 'DÉCONNEXION',
      STATUS: 'STATUT',
      SETTINGS: 'PARAMÈTRES',
      MY_DETAILS: 'MES DÉTAILS',
      LANGUAGE: 'Langue',
      RINGER_DEVICE: 'Sélection appareil sonnerie'
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Conversations actuelles',
      ALL_CONVERSATIONS_TOOL_TIP: 'Conversations précédentes',
      HELP_TOOL_TIP: 'Aide',
      AGENT_CSAT_TOOL_TIP: 'Agent service Satisfaction clients',
      AGENT_STATUS_TOOL_TIP: 'Statut de l’agent',
      AGENT_STATUS_CURRENT : 'Actuel',
      AGENT_STATUS_NEXT : 'Prochaine',
      TRANSLATE_TOOL_TIP: 'Traduire'
    },
    LOGOUT: {
      END_USER_SESSION: 'Session de l’utilisateur final',
      ARE_SURE_WANT_TO_LOG_OUT: 'Voulez-vous vraiment vous déconnecter ?',
      CANCEL: 'Annuler',
      LOG_OUT: 'Déconnexion'                
    }
  },
  de: {
    AGENT_POPOVER: {
      AGENT_NAME: 'Name des Agenten',
      AGENT_NICKNAME: 'Spitzname des Agenten',
      AGENT_ACCOUNT: 'Kontonr.',
      DARK_MODE: 'Dunkel-Modus',
      SOUND: 'Sound',
      LOG_OUT: 'ABMELDEN',
      STATUS: 'STATUS',
      SETTINGS: 'EINSTELLUNGEN',
      MY_DETAILS: 'MEINE DETAILS',                
      LANGUAGE: 'Sprache',
      RINGER_DEVICE: 'Klingelgerät'         
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Aktuellen Unterhaltungen',
      ALL_CONVERSATIONS_TOOL_TIP: 'Frühere Unterhaltungen',
      HELP_TOOL_TIP: 'Hilfe',
      AGENT_CSAT_TOOL_TIP: 'CSAT des Agenten',
      AGENT_STATUS_TOOL_TIP: 'Status des Agenten',
      AGENT_STATUS_CURRENT : 'Aktuell',
      AGENT_STATUS_NEXT : 'Weiter',
      TRANSLATE_TOOL_TIP: 'Übersetzen'                
    },
    LOGOUT: {
      END_USER_SESSION: 'Endbenutzersitzung',
      ARE_SURE_WANT_TO_LOG_OUT: 'Möchten Sie sich wirklich abmelden?',
      CANCEL: 'Abbrechen',
      LOG_OUT: 'Abmelden'                
    }
  },
  es: {
    AGENT_POPOVER: {
      AGENT_NAME: 'Nombre del agente',
      AGENT_NICKNAME: 'Alias del agente',
      AGENT_ACCOUNT: 'N.º cuenta',
      DARK_MODE: 'Modo oscuro',
      SOUND: 'Sonido',
      LOG_OUT: 'CERRAR SESIÓN',
      STATUS: 'ESTADO',
      SETTINGS: 'CONFIGURACIÓN',
      MY_DETAILS: 'MIS DETALLES',                
      LANGUAGE: 'Idioma',
      RINGER_DEVICE: 'Dispositivo de timbre'             
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Conversaciones actuales',
      ALL_CONVERSATIONS_TOOL_TIP: 'Conversaciones anteriores',
      HELP_TOOL_TIP: 'Ayuda',
      AGENT_CSAT_TOOL_TIP: 'CSAT del agente',
      AGENT_STATUS_TOOL_TIP: 'Estado del agente',
      AGENT_STATUS_CURRENT : 'Actual',
      AGENT_STATUS_NEXT : 'Próxima',
      TRANSLATE_TOOL_TIP: 'Traducir'               
    },
    LOGOUT: {
      END_USER_SESSION: 'Sesión de usuario final',
      ARE_SURE_WANT_TO_LOG_OUT: '¿Confirma que desea desconectarse?',
      CANCEL: 'Cancelar',
      LOG_OUT: 'Cerrar sesión'                
    }
  },
  it: {
    AGENT_POPOVER: {
      AGENT_NAME: 'Nome agente',
      AGENT_NICKNAME: 'Nickname agente',
      AGENT_ACCOUNT: 'N. account',
      DARK_MODE: 'Modalità scura',
      SOUND: 'Audio',
      LOG_OUT: 'Disconnessione',
      STATUS: 'STATO',
      SETTINGS: 'IMPOSTAZIONI',
      MY_DETAILS: 'I MIEI DETTAGLI',                
      LANGUAGE: 'Lingua',
      RINGER_DEVICE: 'Selezione del dispositivo di suoneria'              
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Conversazioni in corso',
      ALL_CONVERSATIONS_TOOL_TIP: 'Conversazioni precedenti',
      HELP_TOOL_TIP: 'Aiuto',
      AGENT_CSAT_TOOL_TIP: 'Agente CSAT',
      AGENT_STATUS_TOOL_TIP: 'Stato agente',
      AGENT_STATUS_CURRENT : 'Corrente',
      AGENT_STATUS_NEXT : 'Prossimo',
      TRANSLATE_TOOL_TIP: 'Tradurre'                
    },
    LOGOUT: {
      END_USER_SESSION: 'Sessione utente finale',
      ARE_SURE_WANT_TO_LOG_OUT: 'Sei sicuro di volerti disconnettere?',
      CANCEL: 'Annulla',
      LOG_OUT: 'Disconnessione'                
    }
  },
  'pt-BR': {
    AGENT_POPOVER: {
      AGENT_NAME: 'Nome do Agente',
      AGENT_NICKNAME: 'Apelido do Agente',
      AGENT_ACCOUNT: 'Nº da conta',
      DARK_MODE: 'Modo escuro',
      SOUND: 'Som',
      LOG_OUT: 'SAIR',
      STATUS: 'STATUS',
      SETTINGS: 'CONFIGURAÇÕES',
      MY_DETAILS: 'MEUS DETALHES',                
      LANGUAGE: 'Idioma',
      RINGER_DEVICE: 'Dispositivo de chamada'        
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: 'Conversas Atuais',
      ALL_CONVERSATIONS_TOOL_TIP: 'Conversas Anteriores',
      HELP_TOOL_TIP: 'Ajuda',
      AGENT_CSAT_TOOL_TIP: 'CSAT do Agente',
      AGENT_STATUS_TOOL_TIP: 'Situação do Agente',
      AGENT_STATUS_CURRENT : 'Atual',
      AGENT_STATUS_NEXT : 'Próximo',
      TRANSLATE_TOOL_TIP: 'Tradução'                
    },
    LOGOUT: {
      END_USER_SESSION: 'Sessão de Usuário Final',
      ARE_SURE_WANT_TO_LOG_OUT: 'Tem certeza de que deseja sair?',
      CANCEL: 'Cancelar',
      LOG_OUT: 'Sair'               
    }
  },
  ko: {
    AGENT_POPOVER: {
      AGENT_NAME: '상담사 이름',
      AGENT_NICKNAME: '상담사 별명',
      AGENT_ACCOUNT: '계정 #',
      DARK_MODE: '다크 모드',
      SOUND: '소리',
      LOG_OUT: '로그아웃',
      STATUS: '상태',
      SETTINGS: '설정',
      MY_DETAILS: '내 세부 정보',               
      LANGUAGE: '언어',
      RINGER_DEVICE: '착신음 장치'                  
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: '현재 대화',
      ALL_CONVERSATIONS_TOOL_TIP: '이전 대화',
      HELP_TOOL_TIP: '도움말',
      AGENT_CSAT_TOOL_TIP: '상담사 CSAT',
      AGENT_STATUS_TOOL_TIP: '상담사 상태',
      AGENT_STATUS_CURRENT : '현재',
      AGENT_STATUS_NEXT : '다음',
      TRANSLATE_TOOL_TIP: '번역'                
    },
    LOGOUT: {
      END_USER_SESSION: '최종 사용자 세션',
      ARE_SURE_WANT_TO_LOG_OUT: '로그오프하시겠습니까?',
      CANCEL: '취소',
      LOG_OUT: '로그아웃'               
    }
  },
  'zh-TW': {
    AGENT_POPOVER: {
      AGENT_NAME: '客服專員名稱',
      AGENT_NICKNAME: '客服專員昵稱',
      AGENT_ACCOUNT: '帳戶 #',
      DARK_MODE: '深色模式',
      SOUND: '聲音',
      LOG_OUT: '登出',
      STATUS: '狀態',
      SETTINGS: '設定',
      MY_DETAILS: '我的詳細資料',                
      LANGUAGE: '語言',
      RINGER_DEVICE: '鈴聲裝置'                
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: '目前對話',
      ALL_CONVERSATIONS_TOOL_TIP: '上個對話',
      HELP_TOOL_TIP: '說明',
      AGENT_CSAT_TOOL_TIP: '客服專員 CSAT',
      AGENT_STATUS_TOOL_TIP: '客服專員狀態',
      AGENT_STATUS_CURRENT : '目前',
      AGENT_STATUS_NEXT : '下一個',
      TRANSLATE_TOOL_TIP: '翻譯'               
    },
    LOGOUT: {
      END_USER_SESSION: '結束用戶對話',
      ARE_SURE_WANT_TO_LOG_OUT: '確定要登出？',
      CANCEL: '取消',
      LOG_OUT: '登出'               
    }
  },
  'zh-CN': {
    AGENT_POPOVER: {
      AGENT_NAME: '客服专员姓名',
      AGENT_NICKNAME: '客服专员昵称',
      AGENT_ACCOUNT: '帐号 #',
      DARK_MODE: '深色模式',
      SOUND: '声音',
      LOG_OUT: '注销',
      STATUS: '状态',
      SETTINGS: '设置',
      MY_DETAILS: '我的详细信息',                
      LANGUAGE: '语言',
      RINGER_DEVICE: '铃声设备'              
    },
    NAVBAR: {
      CURRENT_CONVERSATIONS_TOOL_TIP: '当前对话',
      ALL_CONVERSATIONS_TOOL_TIP: '先前对话',
      HELP_TOOL_TIP: '帮助',
      AGENT_CSAT_TOOL_TIP: '客服专员 CSAT',
      AGENT_STATUS_TOOL_TIP: '客服专员状态',
      AGENT_STATUS_CURRENT : '当前',
      AGENT_STATUS_NEXT : '下一步',
      TRANSLATE_TOOL_TIP: '翻译'            
    },
    LOGOUT: {
      END_USER_SESSION: '结束用户会话',
      ARE_SURE_WANT_TO_LOG_OUT: '是否确定要退出登录？',
      CANCEL: '取消',
      LOG_OUT: '注销'              
    }
  },
}
export default localization
