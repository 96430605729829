import React from 'react'
import moment from 'moment'
import styles from '../Theme/Styles/styles.css'
import {
  Provider,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  ActionButton,
  ToggleButton,
  Text,
  Flex,
  Switch,
  ListBox,
  Item,
  Button,
  darkTheme,
  lightTheme,
  Heading,
  StatusLight,
  TooltipTrigger,
  Tooltip,
  ProgressCircle,
  Footer,
  Picker
} from '@adobe/react-spectrum'
import localization from '../lang/localization'
import {
  Logo,
  DarkMode,
  Sound,
  ChatWorkspace,
  AllConversation,
  HelpIcon,
  Profile,
  TranslateIcon
} from '../Theme/Icons/navbar-icons'
import ChevronDown from '@spectrum-icons/workflow/ChevronDown'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Alert from '@spectrum-icons/workflow/Alert'
import BrowserLogCapture from './Features/BrowserLogCapture'
import CCPLogCapture from './Features/CCPLogCapture'

declare const window: any

type contextDataType = {
  data: string
  lang: string
  darkMode: boolean
  chatToggle: boolean
  historyToggle: boolean
  sound: boolean
  csatScore: string
  notificationBadge: boolean,
  activeConversationAvailable: boolean,
  agentStatusData: {
    agentDetails: {
      agentName: string
      nickName: string
    }
    currentStatus: string
    ccpInit: boolean
    status: [
      {
        name: string
        value: string
        id: number
      }
    ]
    away: {
      name: string
      value: string
      list: [string]
    }
    profileStatusData: {
      status: string
    }
  }
  agentFeatureTags: any
  ringerDevice: string
  ringerDevices: any
  version?: string
}
type propsData = {
  contextData: contextDataType
  callBackEvent: (eventType: string, eventData: object) => void
}
const AGENT_STATE_VALUES = ['Pending', 'CallingCustomer', 'Missed', 'Busy', 'AfterCallWork', 'Offline', 'PendingBusy'];
let AGENT_AWAY_STATUS: any = []
const languageList = [{ name: 'English', id: "en" }, { name: '日本語', id: "ja" }, { name: 'français', id: "fr" }, { name: 'Deutsch', id: "de" }, { name: 'español', id: "es" }, { name: 'italiano', id: "it" }, { name: 'português', id: "pt-BR" }, { name: '한국어', id: "ko" }, { name: '中文 (漢語)', id: "zh-TW" }, { name: '中文 (汉语)', id: "zh-CN" }]
class App extends React.Component<propsData, any> {
  oacLoggerFeatureTag: any
  counterTimer: number;
  runCounterTimer: any;
  currentStatus: string;
  newStatus: string;
  clickedAwayStatus: string;
  constructor(props: any) {
    super(props)
    this.state = {
      isAgentStatusOpen: false,
      awayToggle: false,
      hasError: false,
      agentStatusTimer: '00:00:00',
      toggleStatusToolTip: false,
      currentStatus: this.props.contextData.agentStatusData.currentStatus,
      displayTwoStatusFlag: false,
      activeChatFlag: this.props.contextData.activeConversationAvailable,
      statusVariantMap: {
        StartOfDay: 'neutral',
        Online: 'positive'
      }
    }
    AGENT_AWAY_STATUS = this.props.contextData?.agentStatusData?.away?.list || []
    this.chatSelection = this.chatSelection.bind(this)
    this.historySelection = this.historySelection.bind(this)
    this.awayActions = this.awayActions.bind(this)
    this.helpSelection = this.helpSelection.bind(this)
    this.openReleaseNote = this.openReleaseNote.bind(this)
    this.translateSelection = this.translateSelection.bind(this)
    this.logOut = this.logOut.bind(this)
    this.counterTimer = 0;
    this.runCounterTimer = '';
    this.currentStatus = '';
    this.newStatus = '';
    this.clickedAwayStatus = '';
  }

  componentDidMount() {
    // Use this call back event to pass data to Container APP
    this.props.callBackEvent('EVENT_NAME', { message: 'EVENT_MESSAGE' });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: any) {
    try {
      AGENT_AWAY_STATUS = newProps.contextData?.agentStatusData?.away?.list || []
      if (newProps?.contextData?.agentStatusData?.currentStatus
        && newProps.contextData.agentStatusData.currentStatus !== this.state.currentStatus) {
        this.currentStatus = this.state.currentStatus;
        this.newStatus = newProps.contextData.agentStatusData.currentStatus;
        this.setState({ ...this.state, currentStatus: newProps.contextData.agentStatusData.currentStatus });
        this.resetTimer(newProps.contextData.activeConversationAvailable, "statusChange");
      }
      if (this.state.activeChatFlag !== newProps.contextData.activeConversationAvailable) {
        this.setState({ activeChatFlag: newProps.contextData.activeConversationAvailable })
        this.resetTimer(newProps.contextData.activeConversationAvailable, "activeChat");
      }
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  resetTimer = (activeConversationAvailable: boolean, from: string) => {
    //WhenAgent Changes the status manually
    console.log("oc-nav-module", "activeConversationAvailable", activeConversationAvailable,
      "from", from, "current", this.currentStatus, "clickedAwayStatus", this.clickedAwayStatus,
      "newStatus", this.newStatus
    )
    if(this.newStatus === "PC Recovery") this.clickedAwayStatus="PC Recovery";
    // for auto changing STATES ( not considered as a status )
    if (from === "statusChange" && AGENT_STATE_VALUES.includes(this.newStatus)) {
      if (this.clickedAwayStatus) {
        this.currentStatus = this.newStatus;
        this.newStatus = this.clickedAwayStatus;
      }
      return;
    }

    // for initial change ( automatic update from OAC post login) + Available status
    if (from === "statusChange"
      && ((this.newStatus === 'Start of Day' && this.currentStatus !== 'Start of Day')
        || (this.newStatus === 'Available' && this.currentStatus !== 'Available'))) {
      this.stopAgentTimer();
      this.startAgentTimer();
      return;
    }

    // only for manually agent clicked Away status ( State handled and Available/Start of day also handled before)
    // So this block by default will execute for remaining status
    if (from === "statusChange") {
      if (activeConversationAvailable) {
        this.setState({ toggleStatusToolTip: true })
      } else {
        this.setState({ toggleStatusToolTip: false })
        this.clickedAwayStatus = '';
        this.currentStatus = '';
        this.stopAgentTimer();
        this.startAgentTimer();
      }
      return;
    }
    if (from === 'activeChat' && (this.clickedAwayStatus !== '' || this.newStatus === 'PC Recovery')) {
      if (!activeConversationAvailable) {
        this.currentStatus = '';
        this.clickedAwayStatus = '';
        this.setState({ toggleStatusToolTip: false })
        this.stopAgentTimer();
        this.startAgentTimer();
      }
      return;
    }
  }


  displayAgentTimer = () => {
    this.setState({ agentStatusTimer: moment().hour(0).minute(0).second(this.counterTimer++).format('HH:mm:ss') })
  }

  startAgentTimer = () => {
    this.runCounterTimer = setInterval(this.displayAgentTimer, 1000);
  }

  stopAgentTimer = () => {
    clearInterval(this.runCounterTimer);
    this.counterTimer = 0;
  }

  chatSelection() {
    try {
      this.props.callBackEvent('click', { chat: true })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  historySelection() {
    try {
      window.dunamis_analytics = window.dunamis_analytics || {};
      if (typeof window.dunamis_analytics.sendNavbarEvent === 'function') {
        // dunamis render PDC event
        window.dunamis_analytics.sendNavbarEvent({
          value: ''
        })
      }
      this.props.callBackEvent('click', {
        history: true
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  handleCancelBtn(close: any) {
    try {
      window.dunamis_analytics = window.dunamis_analytics || {};
      if (typeof window.dunamis_analytics.sendLogoutConfirmationAnalytics === 'function') {
        // dunamis render PDC event
        window.dunamis_analytics.sendLogoutConfirmationAnalytics({
          subType: 'cancel'
        })
      }
    } catch (e) {
      console.log("oc-navbar-module-client :", e)
    }

    return close();
  }

  logOut() {
    try {
      window.dunamis_analytics = window.dunamis_analytics || {};
      if (typeof window.dunamis_analytics.sendLogoutConfirmationAnalytics === 'function') {
        // dunamis render PDC event
        window.dunamis_analytics.sendLogoutConfirmationAnalytics({
          subType: 'signout'
        })
      }

      this.props.callBackEvent('logout', {
        logout: true
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  openReleaseNote() {
    console.log("open Relase")
    try {
      this.props.callBackEvent('click', {
        openReleaseNotes: true
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  helpSelection() {
    try {
      this.props.callBackEvent('click', {
        help: true
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  translateSelection() {
    try {
      this.props.callBackEvent('click', {
        translate: true
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  awayActions() {
    try {
      this.setState((previousState: any) => ({
        ...this.state,
        awayToggle: !previousState.awayToggle
      }))
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  setStatus = (val: any) => {
    try {
      console.log("OC_NAV_BAR Agent selected the status as", val.currentKey)
      if (val?.currentKey == 'PC Recovery') {
        return
      }
      if (val.currentKey) {
        this.props.callBackEvent('setStatus', {
          status: val.currentKey
        })
        if (AGENT_AWAY_STATUS.includes(val.currentKey)) {
          this.clickedAwayStatus = val.currentKey;
        } else {
          this.clickedAwayStatus = '';
        }
      }
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  changeMode = () => {
    try {
      this.props.callBackEvent('changeMode', {
        darkMode: !this.props.contextData.darkMode
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  changeLocale = (lang: any) => {
    try {
      this.props.callBackEvent('changeLocale', { lang: lang })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  handleRingerDevice = (deviceId: string | number) => {
    try {
      this.props.callBackEvent('ringerDevice', { deviceId })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  toggleSound = () => {
    try {
      this.props.callBackEvent('toggleSound', {
        sound: !this.props.contextData.sound
      })
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  }

  handleAgentStatus = (isOpen: boolean) => {
    try {
      let { isAgentStatusOpen, awayToggle } = this.state;
      if (!isAgentStatusOpen) {
        awayToggle = false;
      }
      this.setState({ awayToggle, isAgentStatusOpen: isOpen });
    } catch (e) {
      this.props.callBackEvent('EXCEPTION', {
        module: 'oc-navbar-module-client',
        message: e.message
      })
    }
  };

  render() {
    const { awayToggle, statusVariantMap, currentStatus, isAgentStatusOpen } = this.state
    const { chatToggle, historyToggle, darkMode, sound, notificationBadge, ringerDevice = '', ringerDevices } = this.props
      .contextData || {
      chatToggle: true,
      historyToggle: false,
      darkMode: false,
      sound: false
    }
    const lang = this.props.contextData.lang || 'en';
    const { agentDetails, status, away, profileStatusData, ccpInit } =
      this.props.contextData.agentStatusData || {}
    return (
      <Provider theme={darkMode ? darkTheme : lightTheme} colorScheme='light'>
        <div className={styles['nav-container']}>
          <div
            className={`${styles['nav-side-bar']}
              ${darkMode
                ? styles['dark-mode-background']
                : styles['light-mode-background']
              }`}
          >
            <div className={styles['company-logo']}>
              <Logo />
              {this.props?.contextData?.version ?
                <div className={styles['releaseNoteVersion']} onClickCapture={this.openReleaseNote}> v{this.props?.contextData?.version}</div>
                : ''}
            </div>
            <Divider size='S' UNSAFE_className={styles['nav-divider']} />
            <div className={styles['nav-link-group-top']} style={{ paddingBottom: "20px" }}>
              <div className={styles['nav-link']}>
                <TooltipTrigger>
                  <Provider
                    UNSAFE_className={styles['nav-link-background']}
                    colorScheme='dark'
                  >
                    {notificationBadge === true ?
                      <ToggleButton
                        isQuiet
                        isEmphasized
                        UNSAFE_className={styles['nav-button-no-pad']}
                        aria-label='Chat Icon'
                        isSelected={chatToggle}
                        onChange={this.chatSelection}
                      >
                        <StatusLight
                          variant='notice'
                          width={10}
                          aria-label='status'
                          UNSAFE_className={`${styles['nav-profile-status']} ${styles['nav-profile-status-noti-badge']}`}
                        />
                        <ChatWorkspace />
                      </ToggleButton> :
                      <ToggleButton
                        isQuiet
                        isEmphasized
                        UNSAFE_className={styles['nav-button-no-pad']}
                        aria-label='Chat Icon'
                        isSelected={chatToggle}
                        onChange={this.chatSelection}
                      >
                        <ChatWorkspace />
                      </ToggleButton>}
                  </Provider>
                  <Tooltip UNSAFE_className={styles.tooltip} width={80}>{localization[lang].NAVBAR.CURRENT_CONVERSATIONS_TOOL_TIP}</Tooltip>
                </TooltipTrigger>
              </div>
              <div className={styles['nav-link']}>
                <TooltipTrigger>
                  <Provider
                    UNSAFE_className={styles['nav-link-background']}
                    colorScheme='dark'
                  >
                    <ToggleButton
                      isQuiet
                      isEmphasized
                      UNSAFE_className={styles['nav-button-no-pad']}
                      aria-label='History Icon'
                      onChange={this.historySelection}
                      isSelected={historyToggle}
                    >
                      <AllConversation />
                    </ToggleButton>
                  </Provider>
                  <Tooltip UNSAFE_className={styles.tooltip} width={80}>{localization[lang].NAVBAR.ALL_CONVERSATIONS_TOOL_TIP}</Tooltip>
                </TooltipTrigger>
              </div>
              <div className={styles['nav-link']}>
                <TooltipTrigger>
                  <Provider
                    UNSAFE_className={styles['nav-link-background']}
                    colorScheme='dark'
                  >
                    <ActionButton
                      isQuiet
                      UNSAFE_className={styles['nav-button-no-pad']}
                      aria-label='Help Icon'
                      onPress={this.helpSelection}
                    >
                      <HelpIcon />
                    </ActionButton>
                  </Provider>
                  <Tooltip UNSAFE_className={styles.tooltip} width={34}>{localization[lang].NAVBAR.HELP_TOOL_TIP}</Tooltip>
                </TooltipTrigger>
              </div>
              <div className={styles['nav-link']}>
                <TooltipTrigger>
                  <Provider
                    UNSAFE_className={styles['nav-link-background']}
                    colorScheme='dark'
                  >
                    <ActionButton
                      isQuiet
                      UNSAFE_className={styles['nav-button-no-pad']}
                      aria-label='Translate Icon'
                      onPress={this.translateSelection}
                    >
                      <TranslateIcon />
                    </ActionButton>
                  </Provider>
                  <Tooltip UNSAFE_className={styles.tooltip} width={80}>{localization[lang].NAVBAR.TRANSLATE_TOOL_TIP}</Tooltip>
                </TooltipTrigger>
              </div>
            </div>

            <BrowserLogCapture agentFeatureTags={this.props.contextData.agentFeatureTags} />
            <CCPLogCapture callBackEvent={this.props.callBackEvent} />

            <div className={styles['nav-link-group-bottom']}>
              <div className={styles['csat-text']}>
                <TooltipTrigger>
                  <ActionButton
                    UNSAFE_className={`
                      ${styles['nav-csat-btn']}
                      ${!(this.props.contextData.csatScore) && styles['nav-csat-score-empty']}
                    `}>
                    {this.props.contextData.csatScore !== undefined
                      ? `${this.props.contextData.csatScore}%`
                      : '- - -'}
                  </ActionButton>
                  <Tooltip UNSAFE_className={styles.tooltip} width={77}>{localization[lang].NAVBAR.AGENT_CSAT_TOOL_TIP}</Tooltip>
                </TooltipTrigger>
              </div>
              <div className={styles['nav-link']}>
                <TooltipTrigger isDisabled={isAgentStatusOpen}>
                  <DialogTrigger
                    isDismissable
                    type='popover'
                    placement='right'
                    onOpenChange={(isOpen) => this.handleAgentStatus(isOpen)}>
                    <ActionButton
                      isQuiet
                      UNSAFE_className={styles['nav-button-no-pad-no-hover']}>
                      {!this.state.toggleStatusToolTip ?
                        <StatusLight
                          variant={profileStatusData[currentStatus]}
                          width={10}
                          aria-label='status'
                          role="status"
                          UNSAFE_className={styles['nav-profile-status']}
                        />
                        :
                        <div className={styles['twoColoredCircle']}></div>
                      }
                      <Profile />
                    </ActionButton>
                    {() => (
                      <PerfectScrollbar options={{ suppressScrollX: true }} className={darkMode ? "navbar-cus-dark-scroll-container" : "navbar-cus-light-scroll-container"}>
                        <Dialog
                          UNSAFE_className={`${styles['nav-mod-popover-container']} ${darkMode
                            && styles['dark-nav-mod-popover-container']}`}
                          margin={0}
                          width={300}
                          height={600}>
                          <Content UNSAFE_className={styles['nav-mod-dialog-content']}>
                            <div className={styles['nav-mod-dialog-top-sec']}>
                              {
                                !ccpInit &&
                                <div className={styles['nav-mod-dialog-loader']}>
                                  <ProgressCircle aria-label="Loading…" isIndeterminate />
                                </div>
                              }
                              <Flex
                                alignItems='center'
                                direction='column'
                                height={90}
                              >
                                <Profile />
                                <Heading level={3} marginTop={7} marginBottom={0}>
                                  {agentDetails && agentDetails.agentName
                                    ? agentDetails.agentName
                                    : 'NA'}
                                </Heading>
                                <Text UNSAFE_className={styles['agent-text']}>
                                  {agentDetails && agentDetails.nickName
                                    ? agentDetails.nickName
                                    : 'NA'}
                                </Text>
                              </Flex>
                              <div className={styles['popover-scroll-container']}>
                                <Flex
                                  direction='column'
                                  marginTop={16}
                                  minHeight={130}
                                >
                                  <Text
                                    marginBottom={7}
                                    UNSAFE_className={styles.font11}
                                  >
                                    {localization[lang].AGENT_POPOVER.STATUS
                                      ? localization[lang].AGENT_POPOVER.STATUS
                                      : 'NA'}
                                  </Text>
                                  <Divider size='S' />
                                  <div className={styles['list-container']}>
                                    <ListBox
                                      aria-label='Status List'
                                      selectionMode='single'
                                      minHeight={72}
                                      height={72}
                                      onSelectionChange={(val) =>
                                        this.setStatus(val)
                                      }
                                      selectedKeys={[currentStatus]}
                                    >
                                      {status?.map((elements: any) => (
                                        <Item
                                          key={elements.value}
                                          textValue={elements.value}
                                          aria-label={elements.value}
                                        >
                                          <StatusLight
                                            variant={
                                              statusVariantMap[elements.name]
                                            }
                                            width={240}
                                            aria-label='acw_status'
                                            role="status"
                                            UNSAFE_className={
                                              styles['status-list-item-style']
                                            }
                                            marginStart={0}
                                          >
                                            {elements.value}
                                          </StatusLight>
                                          <Divider
                                            size='S'
                                            position='absolute'
                                            width={240}
                                            marginTop={30}
                                          />
                                        </Item>
                                      ))}
                                    </ListBox>
                                  </div>
                                  <div className={styles['away-container']}>
                                    <label onClick={this.awayActions}>
                                      <StatusLight
                                        variant='notice'
                                        UNSAFE_className={`${styles['status-list-item-style']} ${styles['navbar-away-label']}`}
                                      >
                                        Away
                                        <ChevronDown
                                          marginStart={10}
                                          maxHeight={18}
                                          UNSAFE_className={
                                            styles['vertical-align-middle']
                                          }
                                          size='S'
                                        />
                                      </StatusLight>
                                    </label>
                                    {awayToggle && (
                                      <div className={styles['away-list']}>
                                        <ListBox
                                          aria-label='Away status options List'
                                          selectionMode='single'
                                          onSelectionChange={(val) =>
                                            this.setStatus(val)
                                          }
                                          selectedKeys={[currentStatus]}
                                        >
                                          {away?.list?.map((list: any) => (
                                            <Item key={list} textValue={list} >
                                              {list == 'PC Recovery' ?
                                                <StatusLight
                                                  width={200}
                                                  UNSAFE_className={
                                                    styles['pc-recovery-blocking']
                                                  }
                                                  variant='notice'
                                                >
                                                  {list}
                                                </StatusLight> :
                                                <StatusLight
                                                  width={200}
                                                  UNSAFE_className={
                                                    styles['vertical-align-middle']
                                                  }
                                                  variant='notice'
                                                >
                                                  {list}
                                                </StatusLight>
                                              }
                                            </Item>
                                          ))}
                                        </ListBox>
                                      </div>
                                    )}
                                    <Divider size='S' />
                                  </div>
                                </Flex>
                              </div>
                            </div>
                            {
                              !this.state.awayToggle &&
                              <div className={styles['navbar-footer-settings-sec']}>
                                <div className={styles['settings-sec']}>
                                  <Flex
                                    direction='column'
                                    UNSAFE_className={
                                      styles[
                                      this.state.awayToggle
                                        ? 'setting-min'
                                        : 'settings-max'
                                      ]
                                    }
                                  >
                                    <Text
                                      UNSAFE_className={styles.font11}
                                      marginTop={4}
                                      marginBottom={7}
                                    >
                                      {localization[lang].AGENT_POPOVER.SETTINGS}
                                    </Text>
                                    <Divider size='S' />
                                    <Flex
                                      justifyContent='space-between'
                                      UNSAFE_className={
                                        styles['vertical-align-middle']
                                      }
                                    >
                                      <label>
                                        <DarkMode />
                                        <Text marginStart={10}>
                                          {localization[lang].AGENT_POPOVER.DARK_MODE}{' '}
                                        </Text>
                                      </label>
                                      <Switch
                                        marginEnd={1}
                                        aria-label='Dark Mode'
                                        isEmphasized
                                        isSelected={darkMode}
                                        onChange={this.changeMode}
                                        alignSelf='end'
                                        data-testid='modeSwitch'
                                      />
                                    </Flex>
                                    <Divider size='S' />
                                  </Flex>
                                  <Flex
                                    justifyContent='space-between'
                                    UNSAFE_className={
                                      styles['vertical-align-middle']
                                    }
                                  >
                                    <label>
                                      <Sound />
                                      <Text marginStart={10}>
                                        {localization[lang].AGENT_POPOVER.SOUND}
                                      </Text>
                                    </label>
                                    <Switch
                                      marginEnd={1}
                                      aria-label='Sound'
                                      isEmphasized
                                      isSelected={sound}
                                      onChange={this.toggleSound}
                                      alignSelf='end'
                                      data-testid='soundSwitch'
                                    />
                                  </Flex>
                                  <Divider size='S' />
                                </div>
                                <div className={`${styles['mydetails-sec']} ${darkMode
                                  && styles['mydetails-sec-dark']}`}>
                                  <Text UNSAFE_className={styles.font11}>
                                    {localization[lang].AGENT_POPOVER.RINGER_DEVICE}
                                  </Text>
                                  <Picker aria-label="English"
                                    UNSAFE_className={styles['transfer-dropdown']}
                                    items={ringerDevices}
                                    defaultSelectedKey={ringerDevice}
                                    onSelectionChange={deviceId => this.handleRingerDevice(deviceId)}>
                                    {(item: any) => <Item key={item.deviceId}>{item.label}</Item>}
                                  </Picker>
                                </div>
                                <div className={`${styles['mydetails-sec']} ${darkMode
                                  && styles['mydetails-sec-dark']}`}>
                                  <div>
                                    <Text UNSAFE_className={styles.font11}>
                                      {localization[lang].AGENT_POPOVER.MY_DETAILS}
                                    </Text>
                                  </div>
                                  <div>
                                    <Text UNSAFE_className={styles.font11}>
                                      {localization[lang].AGENT_POPOVER.LANGUAGE}
                                    </Text>
                                    <Picker aria-label="English"
                                      UNSAFE_className={styles['transfer-dropdown']}
                                      items={languageList}
                                      defaultSelectedKey={lang}
                                      onSelectionChange={val => this.changeLocale(val)}>
                                      {item => <Item key={item.id}>{item.name}</Item>}
                                    </Picker>
                                  </div>
                                </div>
                              </div>
                            }
                            <Flex marginEnd='auto' marginStart='auto'
                              UNSAFE_className={`
                                ${styles['logout-footer']}
                                ${darkMode
                                  ? styles['logout-footer-dark']
                                  : styles['logout-footer-light']
                                }`}>
                              <DialogTrigger>
                                <Button
                                  variant='primary'
                                  UNSAFE_className={styles['nav-mod-logout-button']}
                                  isQuiet
                                  marginTop={5}
                                >
                                  {localization[lang].AGENT_POPOVER.LOG_OUT}{' '}
                                </Button>
                                {(close) => (
                                  <Dialog
                                    size='S'
                                    UNSAFE_className={styles['logout-Popover']}
                                    width={480}
                                    height={229}
                                  >
                                    <Heading
                                      UNSAFE_className={styles['logout-Heading']}
                                    >
                                      {localization[lang].LOGOUT.END_USER_SESSION}
                                      <Alert
                                        aria-label='Negative Alert'
                                        color='negative'
                                        alignSelf='end'
                                        size='S'
                                        marginTop='auto'
                                      />
                                    </Heading>
                                    <Divider
                                      UNSAFE_className={styles['logout-Divider']}
                                    />
                                    <Content>
                                      <Flex>
                                        <Text
                                          UNSAFE_className={styles['logout-Text']}
                                        >
                                          {' '}
                                          {localization[lang].LOGOUT.ARE_SURE_WANT_TO_LOG_OUT}
                                        </Text>
                                      </Flex>
                                    </Content>
                                    <Footer justifySelf="end">
                                      <Button
                                        variant='secondary'
                                        onPress={() => this.handleCancelBtn(close)}
                                        isQuiet
                                      >
                                        {localization[lang].LOGOUT.CANCEL}
                                      </Button>
                                      <Button variant='cta' onPress={this.logOut}>
                                        {localization[lang].LOGOUT.LOG_OUT}
                                      </Button>
                                    </Footer>
                                  </Dialog>
                                )}
                              </DialogTrigger>
                            </Flex>
                          </Content>
                        </Dialog>
                      </PerfectScrollbar>
                    )}
                  </DialogTrigger>
                  {!this.state.toggleStatusToolTip ?
                    <Tooltip UNSAFE_className={styles.tooltip} width={currentStatus ? 120 : 77}>{localization[lang].NAVBAR.AGENT_STATUS_TOOL_TIP}{currentStatus ? ` : ${currentStatus}` : ''}
                    </Tooltip> :
                    <Tooltip UNSAFE_className={styles.tooltip} width={this.currentStatus ? 120 : 77}>
                      <div>{localization[lang].NAVBAR.AGENT_STATUS_CURRENT}{` : ${this.currentStatus}`}</div>
                      <div>{localization[lang].NAVBAR.AGENT_STATUS_NEXT}{` : ${this.newStatus}`}</div>
                    </Tooltip>
                  }
                </TooltipTrigger>
              </div>
              <div className={styles["agentStatusTimerClock"]}>{this.state.agentStatusTimer}</div>
            </div>
          </div>
        </div>
      </Provider>
    )
  }
}
export default App
